 
    <div class="simple_logo">
        <a href="javascript:;" class="logo">
            <img src="assets/images/equentis_logo.png" alt="LOGO">
        </a>
    </div>
 
<section class="kyc_main_body">
    <form #kycform="ngForm">
    <h2 class="page_title_2">"One Small Step for Wealth, One Giant Leap for Wealth Creation."</h2>
<section class="kyc-widget" [ngClass]="userData['user_type']" [class.panVerifyed]="panVerifyed">
    
      <img src="assets/images/left-image-1.png" alt="image" class="userType_image"/>

        <div class="userType_Row" >
           
                <div class="start_with_pan mb-4" *ngIf="!panVerifyed || userData.user_type != 'corporate'">
                   <h4>Let’s Start with your {{getCorporateDocumentType['document_name']}} PAN</h4>
                   <p>Enter PAN Number to fill in your details</p>
                </div>
 

                <div class="start_with_pan mb-4" *ngIf="panVerifyed && userData.user_type == 'corporate'">
                    <h4>Let’s Start with your {{getCorporateDocumentType['document_name']}} details.</h4> 
                 </div>
 
                  
                  <div class="main_data_body" [class.row]="userData.user_type == 'corporate' && panVerifyed">
                  <div class="form-group" [class.col-md-3]="userData.user_type == 'corporate' && panVerifyed">
                    <fieldset [disabled]="onboard_category=='retail_renewal' || onboard_category=='dwn_renewal' || panVerifyed">
                   <mat-form-field appearance="outline">
                       <mat-label>PAN No.</mat-label>
                       <input matInput required maxlength="10" (change)="panVerifyed = false" name="pan" ngModel pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}$"  autocomplete="off" oninput="this.value = this.value.toUpperCase()">
                          <mat-icon matSuffix class="material-icons" style="color: green;font-size: 24px;" *ngIf="panVerifyed">
                           task_alt
                        </mat-icon> 
                   </mat-form-field>
                </fieldset>
                  </div>

                  <div class="form-group" [class.col-md-3]="userData.user_type == 'corporate' && panVerifyed">
                    
                    <div class="input-group" style=" align-items: stretch; "> 
                       
                        <mat-form-field appearance="outline">
                            <mat-label>Upload Pan</mat-label>               
                            <input matInput required name="pan_file" ngModel (click)="panVerifyed?$event.stopPropagation():panFile.click()" readonly class="hidden_matInput" [style.color]="panVerifyed?'':'rgba(0, 0, 0, 0.87) !important'" placeholder="Upload Pan">   
                        </mat-form-field> 
                     
                        <div class="input-group-append">
                            <button [disabled]="panVerifyed" type="button" (click)="panFile.click()" mat-flat-button color="primary" style="min-width: 60px;line-height: 54px;height: 54px;">
                                <input type="file" #panFile (change)="handlePanFileInput($event,'pan_file')" accept=".png,.jpg,.jpeg,.pdf" style="display:none;" />
                                <mat-icon svgIcon="tray-arrow-up"></mat-icon>
                            </button> 
                        </div> 
                        <div class="input-group-append">
                            <a *ngIf="userData.pan_detail && userData.pan_detail.pan_file" mat-flat-button [href]="cdnUrl+'images/pan/'+userData.pan_detail.pan_file" download color="primary" class="ml-2" style="min-width: 60px;line-height: 54px;height: 54px;">
                                <mat-icon svgIcon="download"></mat-icon>
                            </a>
                        </div> 
                     
                    
                        
                    </div> 
                </div> 


                  <div class="form-group" [class.col-md-3]="userData.user_type == 'corporate' && panVerifyed">
                   <p class="p-sm mb-2" *ngIf="userData.user_type == 'individual'">Please enter your date of birth as per your PAN card.</p>
                   <p class="p-sm mb-2" *ngIf="userData.user_type == 'corporate' && !panVerifyed">Please enter your date of incorporation as per your PAN card.</p>
                   <fieldset [disabled]="panVerifyed"> 
                       <mat-form-field appearance="outline" (click)="dobPicker.open()" >
                          <mat-label *ngIf="userData.user_type == 'individual'">Date of Birth</mat-label>
                          <mat-label *ngIf="userData.user_type == 'corporate'">Date of Incorporation</mat-label>
                          <input matInput required readonly [matDatepicker]="dobPicker" name="dob" ngModel  [max]="moment()" (click)="dobPicker.open()" autocomplete="off">
                          <mat-datepicker-toggle matSuffix [for]="dobPicker" ></mat-datepicker-toggle>
                          <mat-datepicker #dobPicker [disabled]="panVerifyed"></mat-datepicker>
                       </mat-form-field> 
                    </fieldset>
                  </div>
                  

                  <div class="form-group" [class.col-md-3]="userData.user_type == 'corporate' && panVerifyed" *ngIf="panVerifyed"> 
                    <fieldset [disabled]="onboard_category=='retail_renewal' || onboard_category=='dwn_renewal' || panVerifyed"> 
                        <mat-form-field appearance="outline">
                        <mat-label>Name</mat-label>
                            <input matInput name="fullName" ngModel>
                        </mat-form-field> 
                   </fieldset>
                  </div>
                </div>
                  
                            <!-- 1	Company
                    2	Partnership
                    3	Trust
                    4	HUF
                    5	Registered Society   -->
                 
                    
                 <div class="corporate_body" *ngIf="panVerifyed && userData.user_type == 'corporate'" [ngModelGroup]="'corporateData'"> 
                 
                    <div class="row"> 

                        <div class="form-group col-md-6">
                           <p class="p-sm mb-2">Gross Annual Income (Rs.)</p>
                           <mat-form-field  class="outline-yes-md" appearance="outline">                  
                               <mat-select name="gross_annual_income" ngModel>
                                  <mat-option value="1 Lac">1 Lac</mat-option>
                                   <mat-option value="1-5 Lac"> 1-5 Lac</mat-option>
                                   <mat-option value="5-10 Lac">5-10 Lac</mat-option>
                                   <mat-option value="10-25 Lac">10-25 Lac</mat-option>
                                   <mat-option value="25 Lacs-1 crore">25 Lacs-1 crore</mat-option>
                                   <mat-option value="> 1 crore">> 1 crore</mat-option>
                                  </mat-select>
                           </mat-form-field>
                        </div>
   
                        <div class="form-group col-md-6">
                           <p class="p-sm mb-2">Net Worth</p>
                           <mat-form-field  class="outline-yes-md" appearance="outline">                  
                               <input matInput name="net_worth" ngModel>   
                           </mat-form-field>
                        </div>
                       
                      
                    </div>
                 
                 
        
                 
                    <div class="row">
                        <!-- <div class="form-group col-md-6">
                            <p class="p-sm mb-2">PAN of Company/HUF/Trust/Partnership Firm.</p>
                            <div class="input-group"> 
                                <mat-form-field  class="outline-yes-md" appearance="outline">                  
                                    <input matInput name="pan_company" [(ngModel)]="corporateData['pan_company']" (click)="pan_company.click()" readonly class="hidden_matInput">   
                                </mat-form-field> 
                                <div class="input-group-append">
                                    <button style=" min-width: 80px;line-height: 40.8px;background-color: #7d7d7d;" type="button" (click)="pan_company.click()" mat-flat-button color="primary">
                                        <input type="file" #pan_company (change)="handleFileInput($event,'pan_company')" accept=".png,.jpg,.jpeg,.pdf" style="display:none;" />
                                        Upload File
                                    </button>
                                </div>  
                            </div>
                         </div>  -->

                         <div class="form-group col-md-6" *ngIf="corporate_doc_list[userData.corporate_doc_type].includes('incorporation_certificate')">
                            <p class="p-sm mb-2">Certificate of Incorporation/Registration Certificate/Deed of Declaration.</p>
                            <div class="input-group"> 
                                <mat-form-field  class="outline-yes-md" appearance="outline">                  
                                    <input matInput required name="incorporation_certificate" [(ngModel)]="corporateData['incorporation_certificate']" (click)="incorporation_certificate.click()" readonly class="hidden_matInput">   
                                </mat-form-field> 
                                <div class="input-group-append">
                                    <button style=" min-width: 80px;line-height: 40.8px;background-color: #7d7d7d;" type="button" (click)="incorporation_certificate.click()" mat-flat-button color="primary">
                                        <input type="file" #incorporation_certificate (change)="handleFileInput($event,'incorporation_certificate')" accept=".png,.jpg,.jpeg,.pdf" style="display:none;" />
                                        Upload File
                                    </button>
                                </div>  
                            </div>
                         </div> 
                         
                         <div class="form-group col-md-6" *ngIf="corporate_doc_list[userData.corporate_doc_type].includes('registered_address')">
                            <p class="p-sm mb-2">Proof of Registered Address.</p>
                            <div class="input-group"> 
                                <mat-form-field  class="outline-yes-md" appearance="outline">                  
                                    <input matInput required name="registered_address" [(ngModel)]="corporateData['registered_address']" (click)="registered_address.click()" readonly class="hidden_matInput">   
                                </mat-form-field> 
                                <div class="input-group-append">
                                    <button style=" min-width: 80px;line-height: 40.8px;background-color: #7d7d7d;" type="button" (click)="registered_address.click()" mat-flat-button color="primary">
                                        <input type="file" #registered_address (change)="handleFileInput($event,'registered_address')" accept=".png,.jpg,.jpeg,.pdf" style="display:none;" />
                                        Upload File
                                    </button>
                                </div>  
                            </div>
                         </div>
                         
    
                         <div class="form-group col-md-6" *ngIf="corporate_doc_list[userData.corporate_doc_type].includes('promoters_photo')">
                            <p class="p-sm mb-2">Photographs, POI, POA, PAN, DIN of Promoters/Partners/Karta/Trustees/WTD/and Two directors in charge of day to day operations.</p>
                            <div class="input-group"> 
                                <mat-form-field  class="outline-yes-md" appearance="outline">                  
                                    <input matInput required name="promoters_photo" [(ngModel)]="corporateData['promoters_photo']" (click)="promoters_photo.click()" readonly class="hidden_matInput">   
                                </mat-form-field> 
                                <div class="input-group-append">
                                    <button style=" min-width: 80px;line-height: 40.8px;background-color: #7d7d7d;" type="button" (click)="promoters_photo.click()" mat-flat-button color="primary">
                                        <input type="file" #promoters_photo (change)="handleFileInput($event,'promoters_photo')" accept=".png,.jpg,.jpeg,.pdf" style="display:none;" />
                                        Upload File
                                    </button>
                                </div>  
                            </div>
                         </div>

                         <div class="form-group col-md-6" *ngIf="corporate_doc_list[userData.corporate_doc_type].includes('balance_sheet')">
                            <p class="p-sm mb-2">Balance sheets for the last 2 financial years.</p>
                            <div class="input-group"> 
                                <mat-form-field  class="outline-yes-md" appearance="outline">                  
                                    <input matInput required name="balance_sheet" [(ngModel)]="corporateData['balance_sheet']" (click)="balance_sheet.click()" readonly class="hidden_matInput">   
                                </mat-form-field> 
                                <div class="input-group-append">
                                    <button style=" min-width: 80px;line-height: 40.8px;background-color: #7d7d7d;" type="button" (click)="balance_sheet.click()" mat-flat-button color="primary">
                                        <input type="file" #balance_sheet (change)="handleFileInput($event,'balance_sheet')" accept=".png,.jpg,.jpeg,.pdf" style="display:none;" />
                                        Upload File
                                    </button>
                                </div>  
                            </div>
                         </div>
                         
    
                         <div class="form-group col-md-6" *ngIf="corporate_doc_list[userData.corporate_doc_type].includes('shareholding_pattern')">
                            <p class="p-sm mb-2">Latest Shareholding pattern certified by CS/WTD/MD.</p>
                            <div class="input-group"> 
                                <mat-form-field  class="outline-yes-md" appearance="outline">                  
                                    <input matInput required name="shareholding_pattern" [(ngModel)]="corporateData['shareholding_pattern']" (click)="shareholding_pattern.click()" readonly class="hidden_matInput">   
                                </mat-form-field> 
                                <div class="input-group-append">
                                    <button style=" min-width: 80px;line-height: 40.8px;background-color: #7d7d7d;" type="button" (click)="shareholding_pattern.click()" mat-flat-button color="primary">
                                        <input type="file" #shareholding_pattern (change)="handleFileInput($event,'shareholding_pattern')" accept=".png,.jpg,.jpeg,.pdf" style="display:none;" />
                                        Upload File
                                    </button>
                                </div>  
                            </div>
                         </div>
 
                         <div class="form-group col-md-6" *ngIf="corporate_doc_list[userData.corporate_doc_type].includes('trust_deed')">
                            <p class="p-sm mb-2">MOA & AOA/Trust Deed/Partnership Deed.</p>
                            <div class="input-group"> 
                                <mat-form-field  class="outline-yes-md" appearance="outline">                  
                                    <input matInput required name="trust_deed" [(ngModel)]="corporateData['trust_deed']" (click)="trust_deed.click()" readonly class="hidden_matInput">   
                                </mat-form-field> 
                                <div class="input-group-append">
                                    <button style=" min-width: 80px;line-height: 40.8px;background-color: #7d7d7d;" type="button" (click)="trust_deed.click()" mat-flat-button color="primary">
                                        <input type="file" #trust_deed (change)="handleFileInput($event,'trust_deed')" accept=".png,.jpg,.jpeg,.pdf" style="display:none;" />
                                        Upload File
                                    </button>
                                </div>  
                            </div>
                         </div>
                         
    
                         <div class="form-group col-md-6" *ngIf="corporate_doc_list[userData.corporate_doc_type].includes('investment_board_resolution')">
                            <p class="p-sm mb-2">Board Resolution for Investment in securities market.</p>
                            <div class="input-group"> 
                                <mat-form-field  class="outline-yes-md" appearance="outline">                  
                                    <input matInput required name="investment_board_resolution" [(ngModel)]="corporateData['investment_board_resolution']" (click)="investment_board_resolution.click()" readonly class="hidden_matInput">   
                                </mat-form-field> 
                                <div class="input-group-append">
                                    <button style=" min-width: 80px;line-height: 40.8px;background-color: #7d7d7d;" type="button" (click)="investment_board_resolution.click()" mat-flat-button color="primary">
                                        <input type="file" #investment_board_resolution (change)="handleFileInput($event,'investment_board_resolution')" accept=".png,.jpg,.jpeg,.pdf" style="display:none;" />
                                        Upload File
                                    </button>
                                </div>  
                            </div>
                         </div>


                         <div class="form-group col-md-6" *ngIf="corporate_doc_list[userData.corporate_doc_type].includes('authorised_sign')">
                            <p class="p-sm mb-2">List of Authorised signatories/Trustees/Coparceners with specimen signatures.</p>
                            <div class="input-group"> 
                                <mat-form-field  class="outline-yes-md" appearance="outline">                  
                                    <input matInput required name="authorised_sign" [(ngModel)]="corporateData['authorised_sign']" (click)="authorised_sign.click()" readonly class="hidden_matInput">   
                                </mat-form-field> 
                                <div class="input-group-append">
                                    <button style=" min-width: 80px;line-height: 40.8px;background-color: #7d7d7d;" type="button" (click)="authorised_sign.click()" mat-flat-button color="primary">
                                        <input type="file" #authorised_sign (change)="handleFileInput($event,'authorised_sign')" accept=".png,.jpg,.jpeg,.pdf" style="display:none;" />
                                        Upload File
                                    </button>
                                </div>  
                            </div>
                         </div>
                         
    
                         <div class="form-group col-md-6" *ngIf="corporate_doc_list[userData.corporate_doc_type].includes('investment_policy')">
                            <p class="p-sm mb-2">Investment Policy of the Company, if any.</p>
                            <div class="input-group"> 
                                <mat-form-field  class="outline-yes-md" appearance="outline">                  
                                    <input matInput required name="investment_policy" [(ngModel)]="corporateData['investment_policy']" (click)="investment_policy.click()" readonly class="hidden_matInput">   
                                </mat-form-field> 
                                <div class="input-group-append">
                                    <button style=" min-width: 80px;line-height: 40.8px;background-color: #7d7d7d;" type="button" (click)="investment_policy.click()" mat-flat-button color="primary">
                                        <input type="file" #investment_policy (change)="handleFileInput($event,'investment_policy')" accept=".png,.jpg,.jpeg,.pdf" style="display:none;" />
                                        Upload File
                                    </button>
                                </div>  
                            </div>
                         </div>

                         <div class="form-group col-md-6" *ngIf="corporate_doc_list[userData.corporate_doc_type].includes('memorandum_certificate')">
                            <p class="p-sm mb-2">Copies of the Memorandum and Articles of Association and certificate of incorporation.</p>
                            <div class="input-group"> 
                                <mat-form-field  class="outline-yes-md" appearance="outline">                  
                                    <input matInput required name="memorandum_certificate" [(ngModel)]="corporateData['memorandum_certificate']" (click)="memorandum_certificate.click()" readonly class="hidden_matInput">   
                                </mat-form-field> 
                                <div class="input-group-append">
                                    <button style=" min-width: 80px;line-height: 40.8px;background-color: #7d7d7d;" type="button" (click)="memorandum_certificate.click()" mat-flat-button color="primary">
                                        <input type="file" #memorandum_certificate (change)="handleFileInput($event,'memorandum_certificate')" accept=".png,.jpg,.jpeg,.pdf" style="display:none;" />
                                        Upload File
                                    </button>
                                </div>  
                            </div>
                         </div>

                         <div class="form-group col-md-6" *ngIf="corporate_doc_list[userData.corporate_doc_type].includes('pass_book')">
                            <p class="p-sm mb-2">Bank pass-book/bank statement in the name of HUF.</p>
                            <div class="input-group"> 
                                <mat-form-field  class="outline-yes-md" appearance="outline">                  
                                    <input matInput required name="pass_book" [(ngModel)]="corporateData['pass_book']" (click)="pass_book.click()" readonly class="hidden_matInput">   
                                </mat-form-field> 
                                <div class="input-group-append">
                                    <button style=" min-width: 80px;line-height: 40.8px;background-color: #7d7d7d;" type="button" (click)="pass_book.click()" mat-flat-button color="primary">
                                        <input type="file" #pass_book (change)="handleFileInput($event,'pass_book')" accept=".png,.jpg,.jpeg,.pdf" style="display:none;" />
                                        Upload File
                                    </button>
                                </div>  
                            </div>
                         </div>


                         <div class="form-group col-md-6" *ngIf="corporate_doc_list[userData.corporate_doc_type].includes('managing_committee')">
                            <p class="p-sm mb-2">List of Managing Committee members.</p>
                            <div class="input-group"> 
                                <mat-form-field  class="outline-yes-md" appearance="outline">                  
                                    <input matInput required name="managing_committee" [(ngModel)]="corporateData['managing_committee']" (click)="managing_committee.click()" readonly class="hidden_matInput">   
                                </mat-form-field> 
                                <div class="input-group-append">
                                    <button style=" min-width: 80px;line-height: 40.8px;background-color: #7d7d7d;" type="button" (click)="managing_committee.click()" mat-flat-button color="primary">
                                        <input type="file" #managing_committee (change)="handleFileInput($event,'managing_committee')" accept=".png,.jpg,.jpeg,.pdf" style="display:none;" />
                                        Upload File
                                    </button>
                                </div>  
                            </div>
                         </div>


                         <div class="form-group col-md-6" *ngIf="corporate_doc_list[userData.corporate_doc_type].includes('committee_resolution')">
                            <p class="p-sm mb-2">Committee resolution for persons authorised to act as authorised signatories with specimen signatures.</p>
                            <div class="input-group"> 
                                <mat-form-field  class="outline-yes-md" appearance="outline">                  
                                    <input matInput required name="committee_resolution" [(ngModel)]="corporateData['committee_resolution']" (click)="committee_resolution.click()" readonly class="hidden_matInput">   
                                </mat-form-field> 
                                <div class="input-group-append">
                                    <button style=" min-width: 80px;line-height: 40.8px;background-color: #7d7d7d;" type="button" (click)="committee_resolution.click()" mat-flat-button color="primary">
                                        <input type="file" #committee_resolution (change)="handleFileInput($event,'committee_resolution')" accept=".png,.jpg,.jpeg,.pdf" style="display:none;" />
                                        Upload File
                                    </button>
                                </div>  
                            </div>
                         </div>


                         <div class="form-group col-md-6" *ngIf="corporate_doc_list[userData.corporate_doc_type].includes('society_rules_copy')">
                            <p class="p-sm mb-2">True copy of Society Rules and Bye Laws certified by the Chairman/Secretary.</p>
                            <div class="input-group"> 
                                <mat-form-field  class="outline-yes-md" appearance="outline">                  
                                    <input matInput required name="society_rules_copy" [(ngModel)]="corporateData['society_rules_copy']" (click)="society_rules_copy.click()" readonly class="hidden_matInput">   
                                </mat-form-field> 
                                <div class="input-group-append">
                                    <button style=" min-width: 80px;line-height: 40.8px;background-color: #7d7d7d;" type="button" (click)="society_rules_copy.click()" mat-flat-button color="primary">
                                        <input type="file" #society_rules_copy (change)="handleFileInput($event,'society_rules_copy')" accept=".png,.jpg,.jpeg,.pdf" style="display:none;" />
                                        Upload File
                                    </button>
                                </div>  
                            </div>
                         </div>
                         
                  </div>

                 

                 

 


                </div>
                  
                   
                   <div class="mt-4" [class.text-center]="panVerifyed && userData.user_type == 'corporate'">
                        <button *ngIf="!panVerifyed" (click)="submitForm(kycform)" type="submit" mat-raised-button color="accent" style=" min-width: 140px; ">Verify Pan</button>
                        <button *ngIf="(onboard_category=='retail_new' || onboard_category=='dwn_new') && panVerifyed" (click)="reset(kycform)" type="reset" mat-raised-button color="accent" style=" min-width: 140px; margin-right: 20px;">Reset</button>
                        <button *ngIf="panVerifyed" (click)="sendPan(kycform)" type="submit" mat-raised-button color="primary" style=" min-width: 140px;">Continue</button>
                   </div>
               
        </div>
   
</section>
</form>
</section>