import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, interval } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CommonService } from 'src/services/api/common.service';
import { DataFactoryService } from 'src/services/factory/data-factory.service';
import { ServiceFactory } from 'src/services/factory/service-factory.service';

@Component({
  selector: 'app-dwn-pmp',
  templateUrl: './dwn-pmp.component.html',
  styleUrls: ['./dwn-pmp.component.scss']
})
export class DwnPmpComponent implements OnInit { 
  uidUrl:string;
  esignData:any; 
  messageSubscription:any;
  private pageReload = new BehaviorSubject<any>(false); 
  constructor(  
    private route: ActivatedRoute,
    private router:Router,
    private commonService: CommonService,
    private dataFactory: DataFactoryService,
    private serviceFactory: ServiceFactory,
    ) { 
       debugger;
  
       this.uidUrl = this.route.snapshot.queryParams.uid;
  
       if(this.route.snapshot.queryParams.requestid){
        this.esignData =this.route.snapshot.queryParams;
       }
  
    
       let counsellor_preview = this.route.snapshot.queryParams.counsellor_preview; // counsellor_preview for trackerr side
       if(counsellor_preview){
          this.router.navigate(['esign'],{ 
            queryParams: {counsellor_preview: counsellor_preview},
            relativeTo: this.route
          });
       }else{
        if(this.uidUrl){
          this.getDataByUid('load');
        }
       }

    
      
      
       
      
   
      }
   
  
      async getDataByUid(mode:any){ 
  
        this.serviceFactory.loadingStart("body","Please wait while loading...","");
             
        let loadingMessages = [
          "Please hold on a little longer...",
          "Taking a moment to process...",
          "Almost there, just a few more seconds...",
          "Hang tight while we work in the background...",
          "Sit back and relax, we're working on it..."
        ];
        
        this.messageSubscription = interval(3000) // Change message every 5 seconds, adjust as needed
        .subscribe(() => {
          const randomIndex = Math.floor(Math.random() *  loadingMessages.length);
          const randomMessage =  loadingMessages[randomIndex];
          this.serviceFactory.loadingStart("body", randomMessage, "");
        });
        
  
        let getUserData = await this.commonService.getData_await(`dwLTAgreement/fetchUserUrlDetails/${this.uidUrl}`);
        console.log(getUserData);
       if(!getUserData || !getUserData.status){ 
        this.pageReload.next(true);
        return
       }
       
        if(this.esignData){
           let updateESign= await this.commonService.postData_await('eSign/downloadAndUpdateESign',{user:getUserData.data,esignRes:this.esignData});
           if(!updateESign || !updateESign.status){
            this.pageReload.next(true);
            return
           }
           getUserData = await this.commonService.getData_await(`dwLTAgreement/fetchUserUrlDetails/${this.uidUrl}`);
           if(!getUserData || !getUserData.status){
            this.pageReload.next(true);
            return
           }
        }
  
        let storeData = getUserData.data; 
        storeData['url']=this.uidUrl;
        if(this.esignData){
          storeData['esignData']=this.esignData;
        }
        this.dataFactory.set_userData(storeData); 
  
        this.serviceFactory.loadingStop("body", "");
        this.messageSubscription.unsubscribe();
      }
  
  
     
    
  
      ngOnInit(): void {
        
        this.pageReload.subscribe(val => {
           debugger
           if(val && this.uidUrl){
            setTimeout(() => {
              this.messageSubscription.unsubscribe();
              this.getDataByUid('reload');
            }, 5000);
           }
        });
  
        this.dataFactory.get_userData().subscribe(async res => {
          if (res.url) {
            if (res.current_status == "approved") {
              if(res.payment_due_id && res.payment_due_id>0 && res.is_agreement_resign!=1){
                this.router.navigate(['payment'], {
                  queryParams: { uid: res.url },
                  relativeTo: this.route
                });     
            }else if(!res.payment_due_id && !res.enachSubmitted && res.is_agreement_resign!=1){
              this.router.navigate(['enach'],{ 
                queryParams: {uid: res.url},
                relativeTo: this.route
              });         
            }else if(res.enachSubmitted){
              this.router.navigate(['payment-success'],{ 
                queryParams: {uid: res.url},
                relativeTo: this.route
              }); 
            }
            } else {
              this.router.navigate(['esign'],{ 
                queryParams: {uid: res.url},
                relativeTo: this.route
              }); 
            }
          }
        });
      }
       
  }
 