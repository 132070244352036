import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'
import * as CryptoJS from 'crypto-js';

import * as moment from 'moment'; 


@Injectable({
  providedIn: 'root'
})
export class ServiceFactory {
  tokenFromUI: string = "0123456789123456";
  activeElm:any;

  constructor() {}


  encrypt(msg) {
    // Encrypt
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    return CryptoJS.AES.encrypt(
      msg, _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString();
      
  }

  decrypt (msg) {
    // Decrypt
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);

    return CryptoJS.AES.decrypt(
      msg, _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);
    
  }


 // # Region notification
   // # Region notification
  notification(message: string, type: any) {
    //debugger
     this.activeElm = document.activeElement;
    
    let messageType:any
    if(type==true){
      messageType ='success';
    }else if(type==false){
      messageType ='error';
    }else{
       messageType = type
    } 

   // messageType = (type ? 'success' : 'error');   
    Swal.fire({
      toast: true,
      position: 'bottom-end',
      icon: messageType, 
      title: message,
      showConfirmButton: false,
      customClass: {
        container: 'notification',
        popup: messageType,
      },
      timer: 3000
    });
    debugger
    if(this.activeElm && this.activeElm!=null){
      this.activeElm.focus()
    }
    
    //console.log(this.activeElm);
  }
  //# End - Region notification


  // # Region Loader

  loadingStart(elm: any, msg: string, btn: string) {
   // debugger  
    let ifLoading=document.querySelector('#loading-'+elm.slice(1)+'-overlay');
    if(ifLoading!=null){
      ifLoading.remove();
    }
    let target=document.querySelector(elm);
    if(target!=null){
        target.style.position = "relative";         
        var wrapper = document.createElement('div');
        wrapper.className = 'loading-overlay-wrapper';
        wrapper.id = 'loading-'+elm.slice(1)+'-overlay';    
        
        var container = document.createElement('div');
        container.className = 'loading-container';
        container.innerHTML = msg;
        wrapper.appendChild(container);
        
        var icon = document.createElement('i');
        icon.className = 'loading-spinner'; 
        container.appendChild(icon);    

        target.appendChild(wrapper);  
    }
    
   
  }

  loadingStop(elm: string, btn: string) {
  //  debugger
    let removeElm=document.querySelector('#loading-'+elm.slice(1)+'-overlay');
    if(removeElm!=null){
      removeElm.remove();
    }

  }

  //# End - Region Loader




  msgPop(msg,type) {  
    debugger  
    Swal.fire({
      buttonsStyling: false, 
      allowOutsideClick:false,
      showCloseButton: false, 
      customClass: { 
        container: 'modal-yes-no swal-msg-type',
        popup: 'modalBG',
        actions: 'modal-btn-yes-no mb-4',
        closeButton: 'btn-modal-dismiss',
        icon: 'modal-icon-top',
        confirmButton: 'mat-raised-button mat-button-base mat-primary', 
      },
        title:type,	
        html: msg,
        icon:type,
      }) 
 }



 getMonthsFromToDate(dateStart,dateEnd){
   debugger 
   dateStart = moment(dateStart);
   dateEnd = moment(dateEnd)

    var timeValues = [];

    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
      timeValues.push(dateStart.format('YYYY-MM'));
      dateStart.add(1,'month');
    }

   return timeValues.length
 }


 encrypt_crypto(data:any,key:string) {
  // debugger
   return CryptoJS.AES.encrypt(data, key).toString();
}

decrypt_crypto(data:any,key:string) {
  // debugger
   return CryptoJS.AES.decrypt(data , key).toString(CryptoJS.enc.Utf8);
}


}
