import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/services/api/common.service';
import { DataFactoryService } from 'src/services/factory/data-factory.service';
import { ServiceFactory } from 'src/services/factory/service-factory.service';
import Swal from 'sweetalert2';
import { ExternalLibraryService } from './util';
declare let Razorpay: any;

const BANK_DETAILS = [
  {
  "bank_id":1,
  "bank_code": "bob",
  "Account_Name": "EQUENTIS WEALTH ADVISORY SERVICES LIMITED",
  "IFSC_Code": "BARB0BULLIO - BARB0(Zero)BULLIO",
  "Branch": "M.J. MARKET, MUMBAI",
  "Bank_Name": "Bank of Baroda",
  "Account_No": "06950200000577" 
}
];

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentComponent implements OnInit {
  onboard_category:string;
  userData:any 
  storePaymentData = {
    product:{}
  } 
  bankdetails:any = [];
  activeBanke:any={}; 
  qrdata; 
 

  
  @ViewChild('paymentfileInput') paymentfileInput: ElementRef;



/***************************** */

   
  constructor(
    private dataFactory: DataFactoryService,
    private commonService:CommonService ,
    private router:Router,
    private serviceFactory: ServiceFactory,
    private razorpayService: ExternalLibraryService, 
    private cd:  ChangeDetectorRef,
    private route: ActivatedRoute,
  ) { 
    debugger
    this.onboard_category = route.parent.snapshot.data['roles']; 
    
    this.dataFactory.get_userData().subscribe(res => { 
      if(res.url){
        this.userData =res; 
        this.getPaymentDetailsAPI();
      } 
     })
     this.bankdetails=BANK_DETAILS;
     this.activeBanke=this.bankdetails[0]
   }

   ngAfterViewInit(){
     
  }
  ngOnInit(): void {
    

    this.razorpayService
    .lazyLoadLibrary('https://checkout.razorpay.com/v1/checkout.js')
    .subscribe(); 
  }

 

 

  getPaymentDetailsAPI() {
    debugger
     
    let apiUrl ="";
    if(this.onboard_category=="retail_new"){
      apiUrl="onboarding/getPaymentDetails";
    }else if(this.onboard_category=="dwn_new"){
      apiUrl="dwOnboarding/getPaymentDetails_dhanwaan";
    }else if(this.onboard_category=="retail_renewal"){
      apiUrl="subscriptionRenewal/getPaymentDetails_renewal";
    }else if(this.onboard_category=="dwn_renewal"){
      apiUrl="dwSubscriptionRenewal/getPaymentDetails_dhanwaan_renewal";
    }else if(this.onboard_category=="pmp_lt"){
      apiUrl="dwLTAgreement/getLtPaymentDetails";
    }else if(this.onboard_category=="dwn_lt"){
      apiUrl="dwLTAgreement/getDwnLtPaymentDetails";
    }else{
     }

        this.commonService.postData(apiUrl,{
          leadId:this.userData.leadID,
          userId:this.userData.user_id,
          ob_customer_id:this.userData.ob_customer_id
        }).subscribe((res) => {
          debugger
          if(res.status){
              this.storePaymentData = res.data;
                this.bindQrCode(); 
               if(this.userData.agreement_type=="otp"){
                this.sendOnboardSignedAgreementCopy()
               }
              //this.ShowAttention();
          }else{
            this.storePaymentData = {
              product:{}
            } 
          }
          
      });
    
  }
  bindQrCode(){
    debugger
    let am =this.storePaymentData.product['amount']; 
    let totalWith_GST =am+this.getGST(am);
    this.qrdata=`upi://pay?pa=MSEQUENTISWEALTHADVISORYSERVICESLIMITED.eazypay@icici&cu=INR&M/S.EQUENTIS WEALTH ADVISORY SERVICES LIMITED&am=${totalWith_GST}`;
    
   // this.qrdata=`upi://pay?pa=equentiswealthadvisoryservicespvtltd@icici&pn=EZYS9619650040&cu=INR&mc=5411&am=${totalWith_GST}`;
   }

  sendOnboardSignedAgreementCopy(){
    debugger 
    let apiUrl ="";
    if(this.onboard_category=="retail_new"){
      apiUrl="onboarding/sendOnboardSignedAgreementCopy";
    }else if(this.onboard_category=="dwn_new"){
      apiUrl="dwOnboarding/sendOnboardSignedAgreementCopy_Dhanwaan";
    }else if(this.onboard_category=="retail_renewal"){
      apiUrl="subscriptionRenewal/sendOnboardSignedAgreementCopy_renewal";
    }else if(this.onboard_category=="dwn_renewal"){
      apiUrl="dwSubscriptionRenewal/sendOnboardSignedAgreementCopy_dhanwaan_renewal";
    }else{
     }
     
      this.commonService.postData(apiUrl,{
        leadId:this.userData.leadID,
        ob_customer_id:this.userData.ob_customer_id 
      }).subscribe((res) => {
        debugger 

    });
    }


 ShowAttention(){
 // var el:any = document.querySelector(".payment_body");
  Swal.fire({ 
    title: 'Attention!',
//    html: '<div style=" line-height: 26px; ">Please confirm that payment is being paid from your own account. <p style="margin: 0;margin-top: 25px;font-weight: 600;color: #717171;font-size: 12px;margin-bottom: -8px;">Note : Save Rs.300 on making payment via UPI or payment into the Bank Account!</p></div>',
      html: '<div style=" line-height: 26px; ">Please confirm that payment is being paid from your own account.</div>',
   // icon: 'warning',
   //target: el, 
    customClass: {
        confirmButton: 'mat-flat-button mat-button-base mat-primary bg-primary',
        cancelButton: 'mat-stroked-button mat-button-base ',
        container: 'modal-yes-no Modal_Delete', 
        actions: 'modal-btn-yes-no mb-4', 
        header: 'pt-4', 
    },
    width: '36em',
    showCloseButton: true,
    buttonsStyling: false,
    showCancelButton: true,
    confirmButtonText: 'Confirm',
    cancelButtonText: 'Cancel' , 
    focusConfirm:false, 
    focusCancel:true,     
   }).then((result) => {
      if (result.isConfirmed) {
       // this.razerPayAPI()
      }
    })
}
  
 
makePayment(){
  this.razerPayAPI()
}
 

razerPayAPI() {
debugger
let pay_source;
if(this.onboard_category=="retail_new"){
  pay_source="new";
}else if(this.onboard_category=="dwn_new"){
  pay_source="new";
}else if(this.onboard_category=="retail_renewal"){
  pay_source="renewal";
}else if(this.onboard_category=="dwn_renewal"){
  pay_source="renewal";
}else if(this.onboard_category=="pmp_lt" || this.onboard_category=="dwn_lt"){
  pay_source="lt";
}else{
 }

  this.commonService.initiateRazorPayment({
    "user_id": this.userData['user_id'],
    "lead_id": this.userData['leadID'],
    "email": this.storePaymentData['email'],
    "name": this.storePaymentData['userName'],
    "phone": this.storePaymentData['phone'],
    "product_id": this.storePaymentData.product['productId'].toString(),
    "amount": this.storePaymentData.product['amount'],
    "total_amount": 1,
    "discount": 0,
    "gst": this.getGST(this.storePaymentData.product['amount']),
    "pay_source":pay_source,
    "ob_customer_id":this.userData.ob_customer_id
  }).subscribe((data)=> {
    this.serviceFactory.notification(data.message,data.status);
     if(data.status) {
      this.proceed(data.data);
    }
  })
}
  
// amount: 15000
// description: "product related desc"
// email: "vk@yopmail.com"
// order_id: "order_HCZBBNP50MinVc"
// phone: "+91-9960071484"
// transaction_id: "rr_pay_1621408843943"
// user_name: "Test User"
proceed(res) {
  let RAZORPAY_OPTIONS = {
    "key": environment.razerPay.key,
    "amount":res.amount,
    "name":res.userName,
    "order_id":res.order_id,
    "description":res.description,
    "image": "https://researchandranking.com/assets/logo.png",
    "prefill": {
      "name":res.userName,
      "email": res.email,
      "contact":res.phone,
      "method": "rz-pay"
    },
    "modal": {},
    "theme": {
      "color": "#084ab3"
    }
  };
  // this.RAZORPAY_OPTIONS.amount = 100 + '00';

  // binding this object to both success and dismiss handler
  RAZORPAY_OPTIONS['handler'] = this.razorPaySuccessHandler.bind(this);

  // this.showPopup();

  let razorpay = new Razorpay(RAZORPAY_OPTIONS);
  razorpay.on('payment.failed', function (response){
    this.serviceFactory.msgPop(response.error.reason,'error');
  });
  razorpay.open();
}

// razorpay_order_id: "order_HCZLxqp8STL3mu"
// razorpay_payment_id: "pay_HCZMTQiQnkSM1b"
// razorpay_signature: "42463d89cd44837b50924569cf1e47d4472fbc352a5d4f31fe237f3e2ae92c74"

public razorPaySuccessHandler(response) {

  this.commonService.createRazorPayment({
    "razorpay_order_id": response.razorpay_order_id,
    "razorpay_payment_id": response.razorpay_payment_id,
    "razorpay_signature": response.razorpay_signature,
    "payment_due_id": this.userData['payment_due_id'],
    "sale_source_id": this.userData['sale_source_id'],
    "sales_type_id": this.userData['sales_type_id'],
    "ob_customer_id":this.userData.ob_customer_id 
  }).subscribe((data)=> {
    this.serviceFactory.notification(data.message,data.status);
    if(data.status) {
     
      if(this.onboard_category=="retail_new"){
        window.location.href = environment.hostName+"enach?uid="+this.userData.url;
      }else if(this.onboard_category=="dwn_new"){
        window.location.href = environment.hostName+"dwn/enach?uid="+this.userData.url;
      }else if(this.onboard_category=="retail_renewal"){
        window.location.href = environment.hostName+"renewal/payment-success?uid="+this.userData.url;
      }else if(this.onboard_category=="dwn_renewal"){
        window.location.href = environment.hostName+"dwn-renewal/payment-success?uid="+this.userData.url;
       }else if(this.onboard_category=="pmp_lt"){
        window.location.href = environment.hostName+"lt-premium-esign/enach?uid="+this.userData.url;
       }else if(this.onboard_category=="dwn_lt"){
        window.location.href = environment.hostName+"lt-esign/enach?uid="+this.userData.url;
       }else{
         
       }

     
  
      
    }
  })
   
}
 
async submitTranProof(tran_proof) {
  debugger
  let files = this.paymentfileInput.nativeElement.files
   if (tran_proof.invalid) {
    return;
   }  

   if(files.length>0){
    let validFile =  await this.fileValidation(files); 
       
    if(!validFile){
      return false
    } 
   }
 
  
   
  
  let apiUrl ="";
  let pay_source;

  if(this.onboard_category=="retail_new" || this.onboard_category=="dwn_new" || this.onboard_category=="retail_renewal" || this.onboard_category=="dwn_renewal"){
    apiUrl="onboarding/savePayment";
  }
  // else if(this.onboard_category=="retail_renewal"){
  //   apiUrl="subscriptionRenewal/savePayment_renewal";
  //   pay_source="renewal";
  // }
  else if(this.onboard_category=="pmp_lt"){
    apiUrl="dwLTAgreement/saveLtPayment";
    pay_source="lt";
  }else if(this.onboard_category=="dwn_lt"){
    apiUrl="dwLTAgreement/saveLtPayment";
    pay_source="lt";
  }else{
   }



   var formData = new FormData();
   
   formData.append('ob_customer_id', this.userData.ob_customer_id );
   formData.append('user_id', this.userData['user_id']);
   formData.append('leadId', this.userData.leadID);
   formData.append('refNo', tran_proof.value.refNo); 
   formData.append('payment_file', files[0]); 
   formData.append('paymentMethod', "upi");
   formData.append('pay_source', pay_source);

   formData.append('payment_due_id', this.userData['payment_due_id']);
   formData.append('sale_source_id', this.userData['sale_source_id']);
   formData.append('sales_type_id', this.userData['sales_type_id']);
   formData.append('amount_paid', this.storePaymentData.product['amount'] + this.getGST(this.storePaymentData.product['amount']));
   
  
    
   

   this.serviceFactory.loadingStart("body","Please wait while loading...","");
  this.commonService.postData(apiUrl,formData).pipe( 
    finalize(() => {  
      this.serviceFactory.loadingStop("body","");
    })
  ).subscribe((res) => { 
    this.serviceFactory.notification(res.message,res.status); 
    if(res.status){
      if(this.onboard_category=="retail_new"){        
        window.location.href = environment.hostName+"enach?uid="+this.userData.url;
      }else if(this.onboard_category=="dwn_new"){
        window.location.href = environment.hostName+"dwn/enach?uid="+this.userData.url;
      }else if(this.onboard_category=="retail_renewal"){
        window.location.href = environment.hostName+"renewal/payment-success?uid="+this.userData.url;
      }else if(this.onboard_category=="dwn_renewal"){
        window.location.href = environment.hostName+"dwn-renewal/payment-success?uid="+this.userData.url;
       }else if(this.onboard_category=="pmp_lt"){
        window.location.href = environment.hostName+"lt-premium-esign/enach?uid="+this.userData.url;
       }else if(this.onboard_category=="dwn_lt"){
        window.location.href = environment.hostName+"lt-esign/enach?uid="+this.userData.url;
       }else{
         
       }
  
    }
  });
}


  fileValidation(elm){ 
    let file = elm[0];
    debugger 
    let validFile = false;

   var fileSize = file.size/1024/1024;	  
   if(fileSize > 5){
       this.serviceFactory.msgPop('Maximum file size should be 5 MB.','error');
      validFile = false;
      return false
    }

    var exts = ["png","jpg","jpeg","pdf"];

    if (file) {
      var get_ext = file.name.split('.'); 
        get_ext = get_ext.reverse();  
        if ( exts.some(function(el){ return el === get_ext[0]})){
         // debugger
         validFile = true;
            return true;
            
      }else{
         this.serviceFactory.msgPop('File format is not supported. <br>Please select &nbsp; (.png, .jpg, .jpeg,.pdf) &nbsp; file  only.','error');
         validFile = false;
         return false
      }
    }
    return validFile
    
}
removeGST(val){
  var get_GST = (Number(val) * 100)/118;  
  return get_GST; 
}
getGST(val){
  //debugger
   var get_GST = (Number(val) * 18)/100;
   return Number(get_GST.toFixed())
}


sendBankDetails(id){
  debugger
  this.serviceFactory.loadingStart("body","Please wait while loading...","");
  this.commonService.postDataOnWebApi('account_details',{
    bank_id:id,user_email:this.storePaymentData['email'],
    ob_customer_id:this.userData.ob_customer_id 
  }).pipe( 
    finalize(() => {  
      this.serviceFactory.loadingStop("body","");
    })
  ).subscribe((res) => {
    debugger
     if(res.success){
      this.serviceFactory.msgPop('Thank you! The bank detail has been sent to <br> <b>'+this.storePaymentData['email']+'</b> successfully.','success');
     }else{
       this.serviceFactory.msgPop(res.message,'error');
     }
});
}

}

