 
<div >
    
    <div class="text-center">
        <h5 style="text-align: center;"><u>CLIENT CONSENT AND DECLARATION</u></h5>
    </div>
    <br>
   
    <p>Equentis Wealth Advisory Services Limited (hereinafter referred to as "EQUENTIS") has prescribed certain eligibility criteria and terms and conditions which are to be followed by the Client. The Client is required to go through each and every condition as mentioned below and verify if he/she is eligible to do online agreement with EQUENTIS. If all the terms and conditions are acceptable to the Client, he/she should mark in "I agree and authorize" box in order to enter into online investment advisory agreement with EQUENTIS: </p>

    <p><strong><u>Client Consent for E-sign</u></strong></p>

    <ul>
       <li>I, am a resident Indian and is permitted to e-sign agreements and avail the services through this process.</li>
       <li>I hereby confirm that before e-signing the KYC documents, I will go through KYC documents and shall ensure that all my information & details are captured properly on the Investment Advisory Services Agreement and all other KYC documents.</li>
       <li>I will affix my own digital signature / e-sign at the relevant pages of agreement and KYC form so as to enable EQUENTIS to e-sign the said agreement. </li>
    </ul>
    <p><strong><u>Client Consent for Aadhar</u></strong></p>
     
    <ul>
        <li>I hereby confirm that data available in Aadhaar is true, correct and updated. If there will be any modification in Aadhaar data, the same will be intimated to EQUENTIS immediately.</li>
        <li>I am aware of other modes of KYC, which are available, and I have chosen the Aadhaar based method voluntarily. My Aadhaar record can be used for sharing of Aadhaar data/ related passcode / XML data / QR code from Aadhaar image / e-Aadhaar etc. with KRA and Registered Intermediaries for purpose of validation of KYC by KRA and as an audit evidence.</li>
        <li>I will have an option to request for deletion of my Aadhaar record.</li>
        <li>I agree to receive information from KRA through SMS/Email on the designated registered number/Email address.</li>
        <li>I am also aware that for Aadhaar OVD based KYC, my KYC request shall be validated against Aadhaar details through KRA. I hereby confirm to share my masked Aadhaar card with readable QR code or my Aadhaar XML/Digilocker XML file, along with passcode and as applicable, with KRA and other Intermediaries with whom I have a business relationship for KYC purposes only.</li>
        <li>I authorize EQUENTIS to access data / information / photograph etc. from UIDAI, for the purpose of signing Investment Advisory Services Agreement.</li>
     </ul>
     

    <p><strong><u>Client Consent for KRA and CKYC</u></strong></p>
    <ul>
        <li>I hereby confirm that data available with KRA/CKYC is true, correct and updated. I authorised EQUENTIS to fetch the data from KRA/CKYC and use the same for Investment Advisory Agreement, if there will be any modification in above data, the same will be intimated to EQUENTIS immediately.</li>
        <li>I hereby declare that the KYC details furnished by me are true and correct to the best of my knowledge and belief and I under-take to inform you of any changes therein, immediately. In case any of the above information is found to be false, untrue, misleading, or misrepresenting, I am aware that I may be held liable for it.</li>
    </ul>

 <p><strong><u>Other Client Consent</u></strong></p>
 
        <ul>
            <li>I am neither politically exposed person, nor related to one.</li>
            <li>There are no action/proceedings initiated/pending initiated by SEBI/Stock Exchange/any other authority for dealing in securities markets during the last 3 years against me.</li>
            <li>I shall make payment of advisory fees from my own  Account (not a third party account).</li>
            <li>Applicable Advisory Fees & other charges as applicable to my investment advisory service agreement are explained to me by the counsellor and I have understood the same.</li>
        </ul>
    
   <p><strong><u>Client Declaration</u></strong></p>
  
   <p>I, have applied to Equentis Wealth Advisory Services Limited <strong>(“Equentis”)</strong> for subscribing to their Investment Advisory services.</p> 

   <p>I declare and confirm that Equentis has informed me about the definition of <strong>“family of client”</strong> as per Regulation 2(gb) of the SEBI (Investment Adviser) Regulation 2013 the exact of the definition is as below: </p>

   <p><strong><i>“Family of client”</i></strong> shall include individual client, dependent spouse, dependent children and dependent parents. </p>
   <p>I hereby confirm that I am not dependent on any of my family member(s) and all the decisions and investment advisory services taken is on my own Individual independent capacity and hence I am not covered under the definition</p>

    <p>I therefore request Equentis to open my advisory services and treat me as an independent individual client.</p>

    <p><strong>Notes:</strong></p>

    <ul>
        <li>EQUENTIS shall reject the application if it is found that the client is barred from trading or any investigation from SEBI or any other regulatory authorities is pending against the said client, decision taken by EQUENTIS shall be final and binding upon the client.</li>
        <li>EQUENTIS does not guarantee the acceptance of agreement submitted by the client and it reserves the right to reject agreement without assigning any reason.</li>
    </ul>








</div>

 