import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/services/api/common.service';
import { DataFactoryService } from 'src/services/factory/data-factory.service';

@Component({
  selector: 'app-rp-accept-thankyou',
  templateUrl: './rp-accept-thankyou.component.html',
  styleUrls: ['./rp-accept-thankyou.component.scss']
})
export class RpAcceptThankyouComponent implements OnInit {
  onboard_category:string;
  userData:any
  constructor(
    private commonService: CommonService,
    private route:ActivatedRoute,
  ) { 
    this.onboard_category = route.parent.snapshot.data['roles']; 
    let uid = route.snapshot.queryParams['uid'];

     let apiUrl ="";
     if(this.onboard_category=="retail_new"){
      apiUrl="onboarding/updatePortfolioAccepted";
    }else if(this.onboard_category=="dwn_new"){
      apiUrl="dwOnboarding/updatePortfolioAccepted_dhanwaan";
    }else if(this.onboard_category=="retail_renewal"){
      apiUrl="subscriptionRenewal/updatePortfolioAccepted_renewal";
    }else if(this.onboard_category=="dwn_renewal"){
      apiUrl="dwOnboarding/updatePortfolioAccepted_dhanwaan";
    }else{
     }


    this.commonService.postData(apiUrl,{leadId: uid}).subscribe((res) => {
      
    })
  }

  ngOnInit(): void {
    
  }

}
