<div class="thk-wrapper">
	<header>
		<div class="container">
			<div class="d-flex justify-content-between align-items-center flex-wrap">
				<div>
					<a href="javascript:void(0)">
						<img src="./assets/images/equentis_logo.png" style=" height: 43px; " alt="LOGO">
					</a>
				</div>
				<div>
					<span class="h-txt">SEBI Registration No.: INA000003874</span>
				</div>
			</div>
		</div>
	</header>
	<div class="container">
		<div class="text-center">
			 

            <div class="animation">
                <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_j0qwy5q5.json"  background="transparent"  speed="0.8"  style="width: 800px; height: 700px;"  loop  autoplay></lottie-player>
            </div>
            
            <h2 class="text-center" style="margin-top: -50px;">Your Risk Profile Accepted Successfully.</h2>

            <!-- <a mat-flat-button href="http://researchandranking.com/" color="primary" class="mt-4" style="text-decoration: none;min-width: 110px; font-size: 18px; ">OK</a>  -->
             
		</div>
	</div>

</div>