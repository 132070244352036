<div class="kyc-h">
    <a href="javascript:;" class="logo">
        <img src="assets/images/equentis_logo.png" alt="LOGO">
    </a>
</div> 

<section>
    <h2 class="page_quot_portfolio">Aha ! Only A Moment Away To Complete This Journey.</h2>
    <div class="anyl-widget">
        <h4 class="text-center">Risk Assessment Report</h4>
        <!-- <p class="sub-h">
            <strong>{{storeAnalysisData['CustomerName']}},</strong> Please Tell us a bit more about yourself.
        </p> -->

        <div class="anyl-bx" *ngIf="storeAnalysisData['ProfileType']">
            <form #elmForm="ngForm" (ngSubmit)="submitForm(elmForm)">
               
         
             <div class="row mb-4">
                <div class="col-12 col-lg-6">
                    <div class="anyl-info">
                        <span>Risk Assessment of</span>
                        <h4 class="mb-0">{{storeAnalysisData['CustomerName']}}</h4>
                    </div>
                </div>
                <div class="col-12 col-lg-2 mb-space">
                    <div class="anyl-info">
                        <span>Prepared on</span>
                        <h4 class="mb-0">{{storeAnalysisData['UpdatedOn'] | date : 'dd LLL yyyy':'GMT'}}</h4>
                    </div>
                </div>

                <div class="col-12 col-lg-2 mb-space">
                    <div class="anyl-info">
                        <span>Your Score</span>
                        <h4 class="mb-0">{{storeAnalysisData.Score}} / {{max_score}}</h4>
                    </div>
                </div>

                <div class="col-12 col-lg-2">
                    <div class="anyl-info grwth-bx" [class] ="storeAnalysisData['ProfileType']">
                        <span>Your Risk Profile</span>
                        <h4 class="mb-0">{{storeAnalysisData['ProfileType']}}</h4>
                    </div>
                </div>
            </div> 

            <div class="row mt-4">
                <div class="col-12">
                    <div class="anyl-info h-auto">
                        <div class="row align-items-center">
                            <div class="col-12 col-lg-7 left-info">
                                <h5>What is {{storeAnalysisData['ProfileType']}} risk portfolio?</h5>
                                <h6>Your {{storeAnalysisData['ProfileType']}} risk Portfolio is characterized by -</h6>
                                

                                <ul *ngIf="storeAnalysisData['ProfileType'] =='Low'">
                                    <li>The chosen businesses may have predictable earnings growth and associated risks.</li>
                                    <li>Low churning strategy with a possible downside of 10-15%.</li>
                                    <li>We choose and oversee portfolios by emphasizing the fundamental framework of our investment strategy. </li>
                                </ul>

                                <ul *ngIf="storeAnalysisData['ProfileType'] =='Moderate'">
                                    <li>The chosen businesses may have predictable earnings growth and associated risks.</li>
                                    <li>Low churning strategy with a possible downside of 10-15%.</li>
                                    <li>We choose and oversee portfolios by emphasizing the fundamental framework of our investment strategy.</li>
                                </ul>

                                <ul *ngIf="storeAnalysisData['ProfileType'] =='High'">
                                    <li>Some of the chosen businesses may be in the "Early Growth Stage" cycle. They may have unpredictable earnings growth potential, but they are likely to experience positive earnings and a potential increase in their valuation multiples.</li> 
                                    <li>Moderate churning with a possible downside of 15-20%.</li>
                                    <li>Portfolio construction is based on a longer investment horizon of more than three  years..</li>
                                </ul>

                            </div>
 

                            <div class="col-12 col-lg-5 rmt-15">
                                <div class="chart_main" style="height: 100%;">
                                    <div class="chart_wrapper" style="height: 100%; margin: 0 auto;">
                                        <div #growthChart id="growthChart" style="height: 250px;"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
 

                        <div class="row mb-4 mt-2" *ngIf="storeAnalysisData['sutabilityAssesment']">
                            <div class="col-lg-auto">
                                <div class="anyl-info grwth-bx"  style="background-color: #9f7e89;"> 
                                    <h4 class="mb-0">Suitability Assessment</h4>
                                </div>
                            </div> 
                            
                            <div class="col-lg mb-space" *ngFor="let item of storeAnalysisData['sutabilityAssesment']; let i = index">
                                <div class="anyl-info">
                                    <span>{{sutabilityB_retail[item.qid]}}</span>
                                    <h4 class="mb-0" matTooltipClass="mat_grid_tooltip" [matTooltip]="item.answer" style=" overflow: hidden; display: block; white-space: pre-line;color: #444444; font-size: 14px; font-weight: normal;line-height: 20px; "
                                    >{{item.answer}}</h4>
                                </div>
                            </div>

                        </div>

                        
                        <!-- <div class="mt-2 mb-2" *ngIf="storeAnalysisData.product!='Mispriced Opportunities' && (onboard_category=='retail_new' || onboard_category=='retail_renewal')"> 
                            <div class="col-6 mb-4"> 
                                <p>Do you want to personalize your portfolio based on a Shariah compliant?</p>
                            
                      
                                <div class="religious_tab" >
                                  <div class="religious_tab_link" (click)="activeReligious = item" [class.active]="activeReligious==item" *ngFor="let item of religious_list">{{item.name}}</div> 
                               </div>
                          
                               <div class="portfolio-error-wrapper" *ngIf="activeReligious.alert">
                                {{activeReligious.alert}}
                               </div> 
                              </div>
                        </div> -->

                        <div class="mt-2 mb-2" > 
                           
                            <p *ngIf="(onboard_category=='retail_new' || onboard_category=='retail_renewal') && storeAnalysisData['portfolioTitle']">
                                <strong>Note :</strong> 
                                {{storeAnalysisData['product']}} {{storeAnalysisData['product']=='5 in 5'?'is':'are'}} low risk {{storeAnalysisData['product']=='5 in 5'?'product':'products'}}. The {{storeAnalysisData['product']=='5 in 5'?'product':'products'}} {{storeAnalysisData['product']}} [with <strong>{{storeAnalysisData['portfolioTitle'].split(' ')[0]}}</strong> portfolio (<strong>{{storeAnalysisData['ProfileType']}}</strong> risk category)] is suitable for you, as you fall under the <strong>{{storeAnalysisData['ProfileType']}}</strong> Risk category.
                            </p>

                            <p *ngIf="onboard_category=='dwn_new' || onboard_category=='dwn_renewal'"><strong>Note :</strong> 
                                The {{storeAnalysisData['product']=='Dhanwaan'?'product':'products'}} {{storeAnalysisData['product']}} {{storeAnalysisData['product']=='Dhanwaan'?'is':'are'}} suitable for you, as you fall under the <strong>{{storeAnalysisData['ProfileType']}}</strong> Risk category.
                            </p>
                            

                            <p class="is_portfolio_changed" *ngIf="storeAnalysisData['is_portfolio_changed']==true && storeAnalysisData['risk_profile']">
                                <strong>Note :</strong> 
                                You have updated your answers of risk profile questionnaire and the profile has been changed from <strong [class] ="storeAnalysisData.risk_profile.old">{{storeAnalysisData.risk_profile.old | titlecase }}</strong> to  <strong  [class] ="storeAnalysisData.risk_profile.new">{{storeAnalysisData.risk_profile.new | titlecase}}</strong>. If you have chosen to change the risk profile to new risk profile, some of the recommended stocks may be replaced with new ones as per your updated risk profile. Please note that you will not receive any updates/alerts on your old stock recommendations.
                            </p>

                           <mat-checkbox class="mat-checkbox-logn-text" color="primary" name="confirmNew" ngModel required #confirmNew="ngModel" [ngClass]="{ 'is-invalid': elmForm.submitted && confirmNew.invalid }">I have been explained all the products with detailed features and benefits by the counsellor and I confirm that I wish to subscribe this product with my own consent.</mat-checkbox>
                        </div>

                    </div>
                </div>
            </div>
            <div class="mt-4 text-center" *ngIf="!agreementPopupRef">
                <button mat-raised-button class="btn-trans mr-3 rw-100" type="button" (click)="reSubmitRisk()" >Re-Submit Risk Profile</button>
                <button class="rw-100 rmt-15 btn-cont"  *ngIf="onboard_category=='retail_new' || onboard_category=='retail_renewal'" mat-raised-button color="primary" >Continue For The Agreement</button>
                <button class="rw-100 rmt-15 btn-cont" *ngIf="20<storeAnalysisData.Score && (onboard_category=='dwn_new' || onboard_category=='dwn_renewal')"  mat-raised-button color="primary" >Continue To The Investment Details</button>
            </div>

        </form>
        </div>
    </div>
</section>