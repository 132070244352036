import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-file-dwn',
  templateUrl: './new-file-dwn.component.html',
  styleUrls: ['./new-file-dwn.component.scss']
})
export class NewFileDwnComponent implements OnInit {
  @Input() userDetails_For_Doc: any;
  @Input() productIds: any;
  constructor() { }

  ngOnInit(): void {
  }
 
  ordinal_suffix_of(i:any) {
    i = Number(i);
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "<sup>st</sup>";
    }
    if (j == 2 && k != 12) {
        return i + "<sup>nd</sup>";
    }
    if (j == 3 && k != 13) {
        return i + "<sup>rd</sup>";
    }
    return i + "<sup>th</sup>";
  }
}
