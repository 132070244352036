import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http'; 
import { finalize, tap } from 'rxjs/operators'; 
import { ServiceFactory } from '../factory/service-factory.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { DataFactoryService } from '../factory/data-factory.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CommonService { 

  logedUserId;
  serverUrl = environment.baseUrl; 
  baseUrlForUserAgreement = environment.baseUrlForUserAgreement; 
  constructor(
    private http: HttpClient,  
    private serviceFactory: ServiceFactory,
    private dataFactory: DataFactoryService,
    private router:Router,
    
    ) {
      
    }
 


// get method request
getData(url:any, params?:any){ 
  return this.http.get<any>(this.serverUrl+url)
}
// post method request
postData(url:any, params?:any){  
return this.http.post<any>(this.serverUrl+url, params)
}

 
 
    

 
    verifyPANQuicko(payload) {    
      return this.http.post<any>(`https://alphabet.researchandranking.com/quickoPanVerify`,payload ).pipe( 
        finalize(() => { 
        })
      );
    }

  
 
 
 veri5digital_upload(payload) {
   debugger 
  return this.http.post<any>(`${environment.aadhaar.api}_upload`, payload).pipe( 
    finalize(() => { 
    })
  );
 }
 

veri5digital_init(payload) : Observable<HttpResponse<Object>>{ 
  debugger
  return this.http.post<HttpResponse<Object>>(`${environment.aadhaar.api}_init`, payload, {observe: 'response'}).pipe(
       tap(resp => {
         debugger
        // resp.headers.get('Location')
       })
  );
}

  


/*************************** */
 

 initiateRazorPayment(data) {
  return this.http.post<any>(`${environment.razerPay.url}rrPayment/initiateRazorPayment`, data)
 }

 createRazorPayment(data) {
  return this.http.post<any>(`${environment.razerPay.url}rrPayment/createRazorPayment`, data)
 }
 
 
 
 /**********************   agreement-existing-user   *********************************** */
 getUserAgreementDataByLeadId(leadId) {
  debugger  
  this.serviceFactory.loadingStart("body","Please wait while loading...","");
 return this.http.post<any>(`${this.baseUrlForUserAgreement}otpAgreement/${leadId}`,{}).pipe( 
   finalize(() => { 
    this.serviceFactory.loadingStop("body","");
   })
 );
}
  
sendOtpAgreementToUser(data) {
  debugger  
 return this.http.post<any>(`${this.baseUrlForUserAgreement}sendSignedAgreementCopy`,data).pipe( 
   finalize(() => { 
   })
 );
}

async getClientipInfo() {
  let get_client_ip = await fetch('https://api64.ipify.org/?format=json').then(response => response.json())
  .then(result => {    
    return result.ip
  })
  .catch(error => {
    return "0.0.0.0"
  });
  return get_client_ip

 // return await this.http.get<any>(`https://api64.ipify.org/?format=json`).toPromise();
 }
 

 /**********************   Dwn-LT-User   *********************************** */
 getUserDetailsForDwnLT(data) {
  return this.http.get<any>(environment.dwLTAgreement+'fetchUserUrlDetails/'+data).pipe( 
    finalize(() => {  
    })
  );
 }

 getUserDetailsbyUserId(data) {
  return this.http.post<any>(`${environment.dwLTAgreement}fetchpmpagreementdetail`,data).pipe( 
    finalize(() => { 
    })
  );
 }

 updatedwnLtESign(data) {
  return this.http.post<any>(`${environment.dwLTAgreement}updateESign`, data).pipe( 
    finalize(() => {  
    })
  );
 }

 getPaymentDetails_dwnLt(data) {
  return this.http.post<any>(`${environment.dwLTAgreement}getDwnLtPaymentDetails`, data).pipe( 
    finalize(() => {  
    })
  );
 }

saveLtPayment(data) {
  this.serviceFactory.loadingStart("body","Please wait while loading...","");
  return this.http.post<any>(`${environment.dwLTAgreement}saveLtPayment`, data).pipe( 
    finalize(() => {  
      this.serviceFactory.loadingStop("body","");
    })
  );
 }

 getUserDetailsForLT(data) {
  return this.http.get<any>(environment.dwLTAgreement+'fetchLtUserUrlDetails/'+data).pipe( 
    finalize(() => {  
    })
  );
 }

 getLtPremiumUserDetailsbyUserId(data) {
  return this.http.post<any>(`${environment.dwLTAgreement}ltfetchagreementdetail`,data).pipe( 
    finalize(() => { 
    })
  );
 }

 updateLtESign(data) {
  return this.http.post<any>(`${environment.dwLTAgreement}updateLtESign`, data).pipe( 
    finalize(() => {  
    })
  );
 }

 getPaymentDetails_LtPremium(data) {
  return this.http.post<any>(`${environment.dwLTAgreement}getLtPaymentDetails`, data).pipe( 
    finalize(() => {  
    })
  );
 }



    // get method request
    getDataFromWebApi(url:any){
      const headerDict = {
        'token': 'APA91bEVhZlqJiStmGMFBRD-b29vSazXgFCiYh7X'
      }
      const requestOptions = {                                                                                                                                                                                 
        headers: new HttpHeaders(headerDict), 
      };
       return this.http.get<any>('https://web.researchandranking.com/web_api/public/api/'+url, requestOptions)
     }

         // get method request
    postDataOnWebApi(url:any,data:any){
      const headerDict = {
        'token': 'APA91bEVhZlqJiStmGMFBRD-b29vSazXgFCiYh7X'
      }
      const requestOptions = {                                                                                                                                                                                 
        headers: new HttpHeaders(headerDict), 
      };
       return this.http.post<any>('https://web.researchandranking.com/web_api/public/api/'+url,data, requestOptions)
     }

     uploadFile(url:any, params?:any){ 
      debugger   
      this.serviceFactory.loadingStart("body","Please wait while loading...","");
      return this.http.post<any>(this.serverUrl+url, params).pipe( 
       finalize(() => {
         this.serviceFactory.loadingStop("body",""); 
       })
     );
    }




    // get method request
    getDataFrom_apipbuat(url:any){ 
       return this.http.get<any>('https://apipb.equentis.com/'+url)
     }
  

  async getData_await(url:any, params?:any): Promise<any> {
      try {
        const data = await this.http.get<any>(this.serverUrl+url).toPromise();
        return data;
      } catch (error) {
        return null;
      }
    }

     async postData_await(url:any, params?:any): Promise<any> {
      try {
        const data = await this.http.post<any>(this.serverUrl+url, params).toPromise();
        return data;
      } catch (error) {
        return null;
      }
    }

}


