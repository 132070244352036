import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/services/api/common.service';
import { DataFactoryService } from 'src/services/factory/data-factory.service';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceFactory } from 'src/services/factory/service-factory.service';

import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { ToWords } from 'to-words';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment'; 
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-otp-popup',
  templateUrl: './otp-popup.component.html', 
  styleUrls: ['./otp-popup.component.scss']
})
export class OtpPopupComponent implements OnInit {
  onboard_category:string;
  userData:any = {};
  leadId; 
  otpSended_Data = {};
  OtpConfig = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '60px',
      'height': '40px'
    }
  };
  @ViewChild('ngOtpInput', { static: false}) ngOtpInput: any;
  moment = moment;
  constructor(
    private route:ActivatedRoute,
    private serviceFactory: ServiceFactory,
    private commonService: CommonService,
    private dataFactory: DataFactoryService,
    private router:Router,   
    public dialogRef: MatDialogRef<OtpPopupComponent>,
    @Inject(MAT_DIALOG_DATA) private OtpPopupData: any,
    private datePipe: DatePipe, 
  ) { 
     debugger
     this.leadId = OtpPopupData.leadId; 
     this.otpSended_Data = OtpPopupData.res.data;
     this.onboard_category = OtpPopupData.onboard_category;
  }


  ngOnInit(): void {
    
    this.dataFactory.get_userData().subscribe(res => { 
      if(res.url){
        this.userData =res;  
          
      } 
     })

  }
 
  submitOtp(){
    debugger   
    let inputValue = this.checkValid(this.ngOtpInput.otpForm.controls);

  if(!this.ngOtpInput.otpForm.valid){
    return false
  } 

  this.agreementPdfGen(inputValue)
  
  

  }

  

  agreementPdfGen(inputValue){
    debugger
    let forThis = this;
    this.serviceFactory.loadingStart("body","Please wait while loading...","");
        
    let option = {
      "tableAutoSize":true
    }    
    var innerHTML = htmlToPdfmake(this.OtpPopupData.pdfHtml.nativeElement.innerHTML,option); 

    // Add page breaks to the PDFMake content
    innerHTML.forEach((element, index) => {
      if (element.style && element.style.indexOf('page-break') !== -1) {
        innerHTML[index] = { text: '', pageBreak: 'before' };
      }
    });

    var dd = {
      pageSize: 'A4',
      pageMargins: [ 40, 40, 40, 80 ],   
      footer: function(currentPage, pageCount) { 
        debugger

        var footerBody:any = [
          [
              {
                  border: [false, true, false, false],
                  layout: {
                      defaultBorder: false, 
                  },
                  style: 'footerTextStyle',
                  table: {
                      body: [
                          [{ text: "OTP : " }, inputValue],
                          [{ text: "Date Time : " }, moment(forThis.OtpPopupData.date).format('DD-MM-YYYY, h:mm a')],
                          [{ text: "Name : " }, forThis.OtpPopupData.user.user_full_name],
                          [{ text: "Email : " }, forThis.OtpPopupData.user.email_id],
                          [{ text: "Phone : " }, forThis.OtpPopupData.user.phoneNo?forThis.OtpPopupData.user.phoneNo:""]
                      ]
                  }
              }
          ]
        ]; 

         // Include the image only if it is not the last page
         if (currentPage !== pageCount && currentPage !== pageCount - 1) {
          footerBody[0].push({
              image: forThis.dataFactory.signImg_of_maish,
              alignment: 'right',
              width: 250,
              border: [false, true, false, false]
          });
         }else{
          footerBody[0].push({
            text: "",
            border: [false, true, false, false]
         });
         }
      
         return {
          layout: {
              defaultBorder: false,
              
          },
          style: 'footerLineStyle',
          table: {
              widths: ['*', '*'],
              body: footerBody
          }
        };
       },
      content:[
       {text:"Investment Advisory Agreement", alignment: 'center',fontSize: 16, bold: true,margin: [0, 0, 0, 0]},
       { 
        canvas: [
         {
          type: 'line',
					x1: 0, y1: 0,
					x2: 100, y2: 0,
					lineWidth: 2,
          color: '#001e5a',
         },
       ],
       margin: [0, 0, 0, 40],
       alignment: 'center'
       },
       
    innerHTML,
      ],
      styles: {
        footerTextStyle: {
          bold: false,
          fontSize: 9,
          color: 'black',          
          lineHeight:0.6,
          margin: [0, 0, 0, 0]
        },
        footerLineStyle: {
          margin: [0, 10, 0, 0]
        }
      },
      defaultStyle: {
        fontSize: 10,
        bold: false,
        lineHeight:1.2
      },

    };

    const pdfDocGenerator = pdfMake.createPdf(dd);
    // pdfMake.createPdf(dd).download("agreement.pdf");
    // return
     
    pdfDocGenerator.getBlob(async (blob) => {
      debugger 
     var file = new File([blob], "RNR_eSigned_Agreement.pdf", {
      type: "application/pdf",
      });
       this.verifyOtp(file,inputValue);
      
 });
 
    
}


  async verifyOtp(fileList,inputValue){
  debugger
  
 

  let get_client_ip = await fetch('https://api64.ipify.org/?format=json').then(response => response.json())
  .then(result => {    
    return result.ip
  })
  .catch(error => {
    return "0.0.0.0"
  });
  
  var payload_upload = new FormData(); 
  
  payload_upload.append('agreement_file', fileList); 
  payload_upload.append('otp', inputValue); 
  payload_upload.append('leadNo', this.leadId);
  payload_upload.append('client_ip', get_client_ip);
  payload_upload.append('email', this.OtpPopupData.user.email_id); 
  payload_upload.append('ob_customer_id', this.userData['ob_customer_id']);
 

  let apiUrl ="";
  if(this.onboard_category=="retail_new"){
    apiUrl="onboarding/verifyOnboardAgreementOtp";
  }else if(this.onboard_category=="dwn_new"){
    apiUrl="dwOnboarding/verifyOnboardAgreementOtp_Dhanwaan";
  }else if(this.onboard_category=="retail_renewal"){
    apiUrl="subscriptionRenewal/verifyOnboardAgreementOtp_renewal";
  }else if(this.onboard_category=="dwn_renewal"){
    apiUrl="dwSubscriptionRenewal/verifyOnboardAgreementOtp_dhanwaan_renewal";
  }else if(this.onboard_category=="dwn_lt"){
    apiUrl="dwLTAgreement/verifypmpotp";
  }else if(this.onboard_category=="pmp_lt"){
    apiUrl="dwLTAgreement/ltverifyotp";
  }else{
    apiUrl ="existingUserAgreement/verifyAgreementOtp"
   }
  
   //this.serviceFactory.loadingStart("body","Please wait while loading...","");
  this.commonService.postData(apiUrl,payload_upload).pipe( 
    finalize(() => { 
     this.serviceFactory.loadingStop("body","");
    })
  ).subscribe((res) => {
    debugger
    this.serviceFactory.notification(res.message,res.status);
    
    let onboardType={
      "retail_new":'',
      "retail_renewal":'renewal/',
      "dwn_new":'dwn/',
      "dwn_renewal":'dwn-renewal/',
      "dwn_lt":'lt-esign',
      "pmp_lt":'lt-premium-esign'
     }

     let responseUrl = environment.hostName+onboardType[this.onboard_category]+"?uid="+this.userData.url; 


     if(!onboardType[this.onboard_category]){
         responseUrl = environment.hostName+"agreement-thankyou?id="+this.leadId; 
     } 
    

    
    if(res.status){  
      this.serviceFactory.loadingStart("body","Please wait while loading...",""); 
      window.location.href =  responseUrl;
     }
  }) 

 

}

reSendOtp(){ 
  debugger
  this.ngOtpInput.otpForm.reset();
  let apiUrl ="";
    if(this.onboard_category=="retail_new"){
      apiUrl="onboarding/sendOnboardOtpForAgreement";
    }else if(this.onboard_category=="dwn_new"){
      apiUrl="dwOnboarding/sendOnboardOtpForAgreement_Dhanwaan";
    }else if(this.onboard_category=="retail_renewal"){
      apiUrl="subscriptionRenewal/sendOnboardOtpForAgreement_renewal";
    }else if(this.onboard_category=="dwn_renewal"){
      apiUrl="dwSubscriptionRenewal/sendOnboardOtpForAgreement_Dhanwaan_renewal";
    }else if(this.onboard_category=="dwn_lt"){
      apiUrl="dwLTAgreement/sendotponmobileandemail";
    }else if(this.onboard_category=="pmp_lt"){
      apiUrl="dwLTAgreement/sendotponmobileandemailforlt";
    }else{
      apiUrl ="existingUserAgreement/sendOtpForAgreement"
     }

     this.serviceFactory.loadingStart("body","Please wait while loading...","");
    this.commonService.postData(apiUrl,{
      leadNo:this.leadId,
      emailId:this.userData['customer_email_id'],
      ob_customer_id:this.userData['ob_customer_id']
    }).pipe( 
      finalize(() => { 
       this.serviceFactory.loadingStop("body","");
      })
    ).subscribe((res) => {
    debugger
    this.serviceFactory.notification(res.message,res.status);
    
 }) 
}

  checkValid(form){
    let forThis=this;
    let fullValue = "";
    Object.keys(form).forEach(function(key) {
      //debugger
      fullValue = fullValue+forThis.ngOtpInput.otpForm.controls[key].value
      if(!forThis.ngOtpInput.otpForm.controls[key].value){
        forThis.ngOtpInput.otpForm.controls[key].setErrors({required: true});
      }else{
        forThis.ngOtpInput.otpForm.controls[key].setErrors(null);
        
      }
    });
    return fullValue;
  }

  onOtpChange(event){
    debugger
    this.checkValid(this.ngOtpInput.otpForm.controls);
  }

}
