<div class="payment_body">
    <header>
        <div class="container">
            <img src="./assets/images/equentis_logo.png" style="filter: brightness(0) invert(1);"alt="logo" />
            <span>Welcome to R&R Payment System</span> 
        </div>
    </header>
     
     
     
        <div class="container mt-5">
            <h2 class="h2">Customer Details</h2>
            <div class="row">
                <div class="col-12 col-lg-4">
                    <label>Full Name</label>
                    <mat-form-field class="outline-yes-md mat-field-block" appearance="outline">
                        <input type="text" readonly matInput  placeholder="John Doe" required  [value]="storePaymentData['userName']">
                    </mat-form-field> 
                </div>
                <div class="col-12 col-lg-4">
                    <label>Phone No.</label>
                    <mat-form-field  class="outline-yes-md mat-field-block" appearance="outline">            
                        <input matInput required name="phone_no" #phone_no
                                    type="tel" readonly
                                    gktTelInput  
                                    [gktTelWithHyphen]="true"
                                    [gktTelInputOptions]="{initialCountry: 'in'}"
                                    [value]="storePaymentData['phone']"> 
                                    
                    </mat-form-field>
                </div>
                <div class="col-12 col-lg-4">
                    <label>Email Id</label>
                    <mat-form-field class="outline-yes-md mat-field-block" appearance="outline">
                        <input type="text"  readonly matInput  placeholder="johndoe@dummy.com" required  [value]="storePaymentData['email']">
                    </mat-form-field> 
                </div>
            </div>
    
            <div class="row">
                <div class="col-md-6">
                    <div class="row_plan_div">
                        <h2 class="h2 mb-4">Wealth Creation Subscription Plans - Order Summary</h2>
            
            
                        <div class="plan_box  mb-4">
                       
                            <div class="subscription_plan_list">
                                <label class="subscription_plan_itom" > 
                                    <span class="round_check_icon"> </span>
                                     
                                   
                                      <span class="subscription_plan_name">
                                          {{storePaymentData.product['productName']}} 
                                          <small *ngIf="onboard_category!='pmp_lt' && onboard_category!='dwn_lt'" >Remaining <b> {{storePaymentData.product['amount']}} </b> will be charged after <b>6 months.</b></small>
                                      </span> 
        
                                      <span class="subscription_plan_price"><span class="subscription_plan_main_price"><mat-icon svgIcon="currency-inr"></mat-icon> {{storePaymentData.product['amount']}} + GST</span>
                                        <small class="subscription_active_plan_for ml-auto" *ngIf="onboard_category!='pmp_lt' && onboard_category!='dwn_lt'">For 6 Months</small>
                                      </span> 
                                    
                                    </label>
                             </div>
        
                             <div class="total_summary_box shadow-3 border-radius-4 mt-4">
                                <div class="row_summary_total">
                                    <span>Subscription Amount {{(onboard_category!='pmp_lt' && onboard_category!='dwn_lt')?'(for 6 months)':''}}</span>
                                    <strong style=" font-weight: normal; "><mat-icon svgIcon="currency-inr"></mat-icon> <span id="total_Subscription_summary">{{storePaymentData.product['amount']}}</span></strong>
                                </div>
                                <div class="row_summary_total">
                                    <span>GST Amount</span>
                                    <strong style=" font-weight: normal; "><mat-icon svgIcon="currency-inr"></mat-icon> <span id="GST_total_main_amount">{{getGST(storePaymentData.product['amount'])}}</span></strong>
                                </div>
                
                                <div class="row_summary_total" style="color: #000;">
                                    <strong>Grand Total</strong>
                                    <strong><mat-icon svgIcon="currency-inr"></mat-icon> <span id="grand_total_main_amount">{{storePaymentData.product['amount'] + getGST(storePaymentData.product['amount'])}}</span></strong>
                                </div>
        
                                <!-- <p style="margin: 0;margin-top: 15px;font-weight: 600;color: #20af31;font-size: 12px;"> *Save Rs.300 on making payment via UPI or payment into the Bank Account!</p> -->
                                
                                
                                
                            </div>
        
                        </div> 
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="mt-0 upi-p text-center  mb-4">
                        <h5 class="text-center mb-0">Pay Using UPI</h5>
                        <span >BHIM UPI / PHONEPE / GOOGLE PAY / PAYTM</span>
                        <div class="text-center" *ngIf="qrdata">
                            <qrcode [qrdata]="qrdata" [width]="200" [errorCorrectionLevel]="'M'" [elementType]="'canvas'"></qrcode>
                        </div>
                        <div class="_t text-center" style=" font-size: 13px; line-height: 18px;">
                           Subscription can be started via, Bank UPI apps, BHIM app or 3rd party apps supporting UPI, like Google Pay, PhonePe and Paytm.Enter amount and pay to Equentis UPI ID MSEQUENTISWEALTHADVISORYSERVICESLIMITED.eazypay@icici 
                        </div>
                    </div>
                </div>
            </div>

            

        

            <div class="mt-4 text-center mb-4 mt-5">
                <h5 class="mb-0">Bank Account Details</h5>
                <span class="d-block">NEFT/RTGS/MPS/UPI</span>
            </div> 

            
<div class="row">
    <div class="col-md-6 mb-4">
        <div class="shadow-3 border-radius-4 Transaction_Proof">

            <h2 class="h2 mb-4">Bank Name : {{activeBanke.Bank_Name}} </h2>

            <div class="bank-info"> 
                <p><span class="fw-600">Account Name : </span> {{activeBanke.Account_Name}}</p>
                <p><span class="fw-600">Account No. : </span> {{activeBanke.Account_No}}</p>
                <p><span class="fw-600">IFSC code : </span> {{activeBanke.IFSC_Code}}</p>
                <p><span class="fw-600">Branch : </span> {{activeBanke.Branch}}</p>
                <p><span class="fw-600">Type : </span> CURRENT ACCOUNT</p>
             </div>
        </div>
    </div>
    <div class="col-md-6 mb-4">
        <div class="shadow-3 border-radius-4 Transaction_Proof">
            <h2 class="h2 mb-4">Submit Transaction Proof</h2>
            <form #tran_proof="ngForm" (ngSubmit)="submitTranProof(tran_proof)">
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field class="outline-yes-md mat-field-block" appearance="outline">
                            <input type="text" matInput  placeholder="Bank Reference No." required name="refNo"  ngModel>
                        </mat-form-field>  
                    </div>
                    
                    <div class="col-md-6">
                        <div class="chsn-file">
                            <input type="file" name="payment_file" #paymentfileInput   
                            (change)="fileValidation($event.target.files)" ngModel
                            accept=".png,.jpg,.jpeg" />
                        </div>
                    </div>
                </div>
                <div class="mt-3 text-center">
                    <button mat-flat-button color="primary" style="min-width: 90px;" >Submit</button>
                </div>
            </form>
        
        </div>
     </div>
</div>

        



     
       

        <div class="border_1 mt-4 mb-2" style=" max-width: 650px; "></div>
    


        <div class="text-center pt-5 pb-2 makePayment_body">
            <p style=" font-weight: 600; ">*In case you are not able to make payment through UPI or Bank Transfer, 
            <button (click)="makePayment()" mat-flat-button color="accent" class="btn_make_payment">Make Payment</button>
            through our partner gateway.
            </p>
       </div>
   
       <div class="payment_response"></div>

    </div>
     
    </div>
     
    <footer class="mt-4">
        <div class="container footer_container">
            <h3>© Research &amp; Ranking</h3>
            <p class="mt-3">Equentis Wealth Advisory Services Limited (“Equentis”) is a SEBI Registered Investment Advisor –
                SEBI Registration No: INA000003874. “Research &amp; Ranking” is the brand under which the Investment
                Advisory Services are rendered.</p>
            <p>Copyright © 2021 Equentis Wealth Advisory Services Ltd. All Rights Reserved.</p>
        </div>
    </footer>
    