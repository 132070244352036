<div class="agrBody">
	<header>
		<div class="container">
			<img src="./assets/images/equentis_logo.png" style="filter: brightness(0) invert(1);"alt="logo" />
		   <span>Welcome to R&R Agreement Sign System.</span> 
		</div>
	 </header>
	 
		 
	<div class="container">
		<div class="text-center">
			 

            <div class="animation">
                <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_j0qwy5q5.json"  background="transparent"  speed="0.8"   loop  autoplay></lottie-player>
            </div>
            
            <h2 class="text-center" style="margin-top: -50px;">You have successfully signed your Agreement.</h2>

            <a mat-flat-button href="http://researchandranking.com/" color="primary" class="mt-4" style="text-decoration: none;min-width: 110px; font-size: 18px; ">OK</a> 
             
		</div>
	</div>

</div>