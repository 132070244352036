import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, interval } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/services/api/common.service';
import { DataFactoryService } from 'src/services/factory/data-factory.service';
import { ServiceFactory } from 'src/services/factory/service-factory.service';

 
@Component({
  selector: 'app-retail-onboarding',
  templateUrl: './retail-onboarding.component.html',
  styleUrls: ['./retail-onboarding.component.scss']
})
export class RetailOnboardingComponent implements OnInit { 
uidUrl:string;
esignData:any; 
messageSubscription:any;
private pageReload = new BehaviorSubject<any>(false); 
constructor(  
  private route: ActivatedRoute,
  private router:Router,
  private commonService: CommonService,
  private dataFactory: DataFactoryService,
  private serviceFactory: ServiceFactory,
  ) { 
     debugger;


     
     this.uidUrl = this.route.snapshot.queryParams.uid;

     if(this.route.snapshot.queryParams.requestid){
      this.esignData =this.route.snapshot.queryParams;
     }

  
  
      if(this.uidUrl){
        this.getDataByUid('load');
      }
    
     
    
 
    }
 

    async getDataByUid(mode:any){ 

      this.serviceFactory.loadingStart("body","Please wait while loading...","");
           
      let loadingMessages = [
        "Please hold on a little longer...",
        "Taking a moment to process...",
        "Almost there, just a few more seconds...",
        "Hang tight while we work in the background...",
        "Sit back and relax, we're working on it..."
      ];
      
      this.messageSubscription = interval(3000) // Change message every 5 seconds, adjust as needed
      .subscribe(() => {
        const randomIndex = Math.floor(Math.random() *  loadingMessages.length);
        const randomMessage =  loadingMessages[randomIndex];
        this.serviceFactory.loadingStart("body", randomMessage, "");
      });
      

      let getUserData = await this.commonService.getData_await(`onboarding/getLeadDetails/${this.uidUrl}`);
      console.log(getUserData);
     if(!getUserData || !getUserData.status){ 
      this.pageReload.next(true);
      return
     }
     
      if(this.esignData){
         let updateESign= await this.commonService.postData_await('eSign/downloadAndUpdateESign',{user:getUserData.data,esignRes:this.esignData});
         if(!updateESign || !updateESign.status){
          this.pageReload.next(true);
          return
         }
         getUserData = await this.commonService.getData_await(`onboarding/getLeadDetails/${this.uidUrl}`);
         if(!getUserData || !getUserData.status){
          this.pageReload.next(true);
          return
         }
      }

      let storeData = getUserData.data; 
      storeData['url']=this.uidUrl;
      if(this.esignData){
        storeData['esignData']=this.esignData;
      }
      this.dataFactory.set_userData(storeData); 

      this.serviceFactory.loadingStop("body", "");
      this.messageSubscription.unsubscribe();
    }


   
  

    ngOnInit(): void {
      
      this.pageReload.subscribe(val => {
         debugger
         if(val && this.uidUrl){
          setTimeout(() => {
            this.messageSubscription.unsubscribe();
            this.getDataByUid('reload');
          }, 5000);
         }
      });

      this.dataFactory.get_userData().subscribe(async res => {
        if (res.url) {
          if (res.currentStatusID == 5) {
            if (!res.paymentStatus) {
              this.router.navigate(['payment'], {
                queryParams: { uid: res.url },
                relativeTo: this.route
              });
            } else if (!res.enachSubmitted) {
              this.router.navigate(['enach'], {
                queryParams: { uid: res.url },
                relativeTo: this.route
              });
            } else {
              this.router.navigate(['payment-success'], {
                queryParams: { uid: res.url },
                relativeTo: this.route
              });
            }
          }else if (res.currentStatusID > 5) {
            if(!res.enachSubmitted){
              this.router.navigate(['enach'], {
                queryParams: { uid: res.url },
                relativeTo: this.route
              });
            }else{
              this.router.navigate(['payment-success'], {
                queryParams: { uid: res.url },
                relativeTo: this.route
              });
            } 
          }else {
            this.router.navigate([goToRoute[res.currentStatusID]], {
              queryParams: { uid: res.url },
              relativeTo: this.route
            });
          }
        }
      });
    }
     
}

 
const goToRoute ={
  null:"login",
  1:"kyc",
  2:"risk-profile",
  3:"portfolio-analysis",
  4:"portfolio-analysis",
  5:"payment", 
 // 6:"enach",
 // 7:"payment-success",
}

