 
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';  
 
import { LoginService } from '../services/api/login.service'; 
 
 
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service'; 
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable()

export class AuthInterceptor implements HttpInterceptor {
  errorData: {};
  url;
  
  constructor(
    private loginService: LoginService,
    private router: Router,
    private cookieService: CookieService ,  
    private route :ActivatedRoute,
    private _snackBar: MatSnackBar
    ) {
     this.url = this.router.url;
    }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> { 
    //debugger
  
   
      
 
       this.url = this.router.url;
      return next.handle(req).pipe(
        map((event: HttpEvent<any>) => {
            // debugger
            if (event instanceof HttpResponse) {
              if(event.ok && event.body.message == "Invalid Token..."){
               // this.loginService.logout(this.url);
              }
            }
         
            
            return event;
        }),
        catchError((error: HttpErrorResponse) => { 
          debugger
          if(error.status==401){
           this.cookieService.delete('trackerr_currentUser'); 
           this.cookieService.delete('trackerr_user_token');
           this.router.navigate(['../login'], {
             queryParams: { returnUrl: this.url },
             relativeTo: this.route 
            } );

           // let link = "./login?returnUrl="+this.url; 
           // this.loginService.logout(this.url);
          }
          if(error.url.includes(environment.aadhaar.api) && (error.status == 200 || error.status == 404)){  
            document.location.href = environment.aadhaar.redirect+error.url.split('_form/')[1]; 
            /*
           if(environment.production){// If Production 
            document.location.href = 'https://prod.aadhaarbridge.com/api/_form/'+error.url.split('_form/')[1];            
           }else{ // If Not Production 
            document.location.href = error.url; 
           }
           */
           
          }else{
            this._snackBar.open("We're sorry, but something went wrong. Please try again later.", 'Ok', {
              horizontalPosition:'end',
              verticalPosition: 'bottom' ,
            }); 
    
          }
            
            
            if (error.error instanceof ErrorEvent) { 
              // A client-side or network error occurred. Handle it accordingly.
              console.error('An error occurred:', error.error.message);
            } else {
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong,
              console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
            }
            // return an observable with a user-facing error message
            this.errorData = {
              errorTitle: 'Oops! Request for document failed',
              errorDesc: 'Something bad happened. Please try again later.'
            };
            return throwError(this.errorData);
        }));
}
}