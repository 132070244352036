import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-old-file-dwn',
  templateUrl: './old-file-dwn.component.html',
  styleUrls: ['./old-file-dwn.component.scss']
})
export class OldFileDwnComponent implements OnInit {
  @Input() userDetails_For_Doc: any;
  @Input() productIds: any;
  constructor() { }

  ngOnInit(): void {
  }

}
