import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/services/api/common.service';
import { DataFactoryService } from 'src/services/factory/data-factory.service';
import { ServiceFactory } from 'src/services/factory/service-factory.service';

@Component({
  selector: 'app-enach-form',
  templateUrl: './enach-form.component.html',
  styleUrls: ['./enach-form.component.scss']
})
export class EnachFormComponent implements OnInit {
  onboard_category:string;
  userData:any;
  salesType_id:any;
  if_enach_discount:any; 
  storePaymentData:any = {
    product:{}
  }  
  storeAutoPaymentBankList:any;

  bankFilterList:any=[];


  modePayment_list = [
    {id:"N", name:"Net Banking"},
    {id:"D", name:"Debit Card"}
  ]
  accountType_list = [
    {code:"SA", name:"Saving Account"},
    {code:"CA", name:"Current Account"}
  ]

  without_non_advisory =false;
  
  constructor(
    private dataFactory: DataFactoryService,
    private commonService:CommonService ,
    private router:Router,
    private serviceFactory: ServiceFactory,  
    private route: ActivatedRoute,
  ) {
    debugger
    this.onboard_category = route.parent.snapshot.data['roles']; 
    this.salesType_id = dataFactory.get_salesType_id;
    this.getautoPaymentBankList()

    this.dataFactory.get_userData().subscribe(res => { 
      if(res.url){
        this.userData =res;  
        this.without_non_advisory = res.without_non_advisory
        this.getPaymentDetailsAPI();
          
      } 
     })
   }

  ngOnInit(): void {
  }

  getPaymentDetailsAPI() {
    debugger
 
   
        this.commonService.postData('autopayment/getAPCustomerDetails',{
          leadId:this.userData.leadID,
          customer_id:this.userData.user_id,
          salesTypeId:this.salesType_id[this.onboard_category],
          sale_source_id:this.userData.sale_source_id 
        }).subscribe((res) => {
          debugger
          if(res.status){
              this.storePaymentData = res.data;
              if(this.storePaymentData['enach_discount_amount'] && this.storePaymentData['enach_discount_amount']>0){
                this.if_enach_discount = this.storePaymentData['enach_discount_amount'];
              }
               
          }else{
            this.storePaymentData = {
              product:{}
            } 
          }
          
      });
    
  }

  getautoPaymentBankList() {
    debugger 
        this.commonService.getData('autopayment/getautoPaymentBankList').subscribe((res) => {
          debugger
          if(res.status){
              this.storeAutoPaymentBankList = res.data;  
          }  
      });
    
  }


  onSubmit(formElm:any) { 
    debugger
        if(!formElm.valid){
          return false
        } 
          
        let formValue = formElm.value;
       
        formValue['customer_id'] = this.userData.user_id;
        formValue['lead_id'] = this.userData.leadID;
        formValue['sale_source_id'] = this.storePaymentData.sale_source_id;
        formValue['pan_no'] = this.storePaymentData.pan_no; 
        formValue['enach_total_amount'] = this.storePaymentData.enach_total_amount;

        formValue['account_number'] = formValue.account_number;
        formValue['bank_code'] = formValue.bank_selected.bank_code;
        formValue['bank_id'] = formValue.bank_selected.id; 
        formValue['enach_payment'] = "EN"; 

        formValue['sales_type_id'] = this.storePaymentData.sales_type_id;
        
        formValue['mobile_no'] = formValue.mobile_no.replace(/\s+/g, '');

       let enach_Success = environment.hostName;
        if(this.onboard_category=="retail_new"){
          enach_Success = environment.hostName+"payment-success?uid="+this.userData.url;
        }else if(this.onboard_category=="dwn_new"){
          enach_Success = environment.hostName+"dwn/payment-success?uid="+this.userData.url;
        }else if(this.onboard_category=="retail_renewal"){
          enach_Success = environment.hostName+"renewal/payment-success?uid="+this.userData.url;
        }else if(this.onboard_category=="dwn_renewal"){
          enach_Success = environment.hostName+"dwn-renewal/payment-success?uid="+this.userData.url;
         }else if(this.onboard_category=="pmp_lt"){
          enach_Success = environment.hostName+"lt-premium-esign/payment-success?uid="+this.userData.url;
         }else if(this.onboard_category=="dwn_lt"){
          enach_Success = environment.hostName+"lt-esign/payment-success?uid="+this.userData.url;
         }else{
         }

         formValue['enach_success_url'] = enach_Success;


        delete formValue.bank_selected;

        this.commonService.postData('autopayment/saveCustomerAutoPaymentDtl',formValue).subscribe((res) => {
          debugger
          if(res.status){
            window.location.href = res.data.redirectUrl;
          }else{
            alert(JSON.stringify(res));
          }
           
      });
        
       }


       onChangeAuthenticationType(event:any,bankSelected:any){
         debugger 
        //  this.serviceFactory.loadingStart("body","Please wait while loading...",""); 

         let jsonData = JSON.stringify(this.storeAutoPaymentBankList); 
         let bank = JSON.parse(jsonData); 
         
         if(event.id=='N'){
          this.bankFilterList = bank.filter((item:any) => item.net_banking.includes("Live")); 
          if(!bankSelected.value.net_banking?.includes("Live")){
            bankSelected.control.setValue('');
           }

         }else if(event.id=='D'){
          this.bankFilterList = bank.filter((item:any) => item.debit_card.includes("Live"));
          if(!bankSelected.value.debit_card?.includes("Live")){
            bankSelected.control.setValue('');
           }
         }else{
           
         }

        //  setTimeout(()=>{                           
        //   this.serviceFactory.loadingStop("body","");
        //  }, 300);

         
         
         debugger
       
      


       }

       getNumber(val:any){
        return Number(val);
       }
}
