import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/services/api/common.service';
import { DataFactoryService } from 'src/services/factory/data-factory.service';

@Component({
  selector: 'app-otp-thankyou',
  templateUrl: './otp-thankyou.component.html',
  styleUrls: ['./otp-thankyou.component.scss']
})
export class OtpThankyouComponent implements OnInit {
  userData:any 
  constructor(
    private commonService: CommonService,
    private route:ActivatedRoute,
  ) { 
    let leadId = route.snapshot.queryParams['id'];
    if(leadId){
        this.commonService.sendOtpAgreementToUser({leadNo: leadId}).subscribe((res) => {
      
      })
    }
    
  }

  ngOnInit(): void {
    
  }

}
