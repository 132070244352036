import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { concat, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, map, switchMap, tap } from 'rxjs/operators'; 
import { RetailDwnAgreementPopupComponent } from 'src/app/agreement/retail-dwn-agreement-popup/retail-dwn-agreement-popup.component';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/services/api/common.service';
import { DataFactoryService } from 'src/services/factory/data-factory.service';
import { ServiceFactory } from 'src/services/factory/service-factory.service';
import Swal from 'sweetalert2'; 

@Component({
  selector: 'app-investment-details',
  templateUrl: './investment-details.component.html',
  styleUrls: ['./investment-details.component.scss'],
  host: {'class': 'page-wrapper'} ,
})
export class InvestmentDetailsComponent implements OnInit {
  onboard_category:string;
  userData:any 
  user_InvestmentDetails= {};

  


  searchType = "";
  moviesLoading = false;

  movies$: Observable<any>;
  moviesInput$ = new Subject<string>();
  minLengthTerm = 3;

  no_of_stocks_preferred_range = "20-26";
  dsclmr_no_of_stocks_preferred_range = false;
  dsclmr_dont_want = false;
  @ViewChild('salesform', { static: true }) salesform: NgForm

  companyAndIndustryData:any = {};

  religious_list:any = [
    {name:"No Restriction", value:"No-Restriction", alert:null },
    {name:"Shariah-Compliant", value:"Shariah-Compliant", alert:"Alcohol, Recreational Cannabis, Banking & Insurance, Pork, Gambling, Tobacco, Movies, Music & Haraam Entertainment stocks will not be recommended to you."},
    {name:"Jain-Compliant", value:"Jain-Compliant", alert:"Tobacco, Liquor, Animal Slaughter & Animal Product Usage stocks will not be recommended to you."},
    {name:"Iskcon-Compliant", value:"Iskcon-Compliant", alert:"Tobacco, Caffeine, Liquor, Animal Slaughter, Animal Product Usage & Hotel Industry stocks will not be recommended to you."}
   ]

   activeReligious = this.religious_list[0];

  constructor(
    public dialog: MatDialog,
    private commonService: CommonService,
    private dataFactory: DataFactoryService,
    private router:Router, 
    private serviceFactory: ServiceFactory,
    private elRef:ElementRef,
     private route: ActivatedRoute,
    private http: HttpClient,
    private config: NgSelectConfig
  ) {
    this.onboard_category = route.parent.snapshot.data['roles']; 

      this.config.notFoundText = 'Custom not found';
      this.config.appendTo = 'body';       
      this.config.bindLabel = 'name';
      this.config.bindValue = 'id';


 
      this.dataFactory.get_userData().subscribe(res => { 
        if(res.url){ 
          debugger
          if(res.currentStatusID==5){
            this.agreementPopup()
          }else{
          this.userData =res;
          this.getDwnInvestmentDetails();  
          }
         
          
        } 
       })
   }

 
  ngOnInit(): void {
   // this.loadSearchData(); 
   this.load_companyAndIndustryData('industry');
   this.load_companyAndIndustryData('company');
   
  }

  load_companyAndIndustryData(api:any){ 
   
    this.commonService.getDataFrom_apipbuat(api).subscribe((res) => {
      this.companyAndIndustryData[api] = res.data; 
    })
  }


  ngAfterViewInit(){
    debugger
    // if(this.onboard_category=="dwn_new"){
    //   this.salesform.form.valueChanges.subscribe(x => {
    //     debugger
        
    //     this.dsclmr_dont_want = false;
 
    //        Object.values(x.industry).forEach((element:any) => {
    //          if(element.id !=null && element.id!=''){
    //            this.dsclmr_dont_want = true;
    //          }
    //        })
 
    //        Object.values(x.company).forEach((element:any) => {     
    //          if(element.id !=null && element.id!=''){
    //            this.dsclmr_dont_want = true;
    //          } 
    //      })
         
    //      if(x.additional_comment !=null && x.additional_comment!=''){
    //        this.dsclmr_dont_want = true;
    //      } 
         
  
 
    //  })
    //  } 
}
 
  onBlurOut(){
    debugger
     
  }
 

   

  getDwnInvestmentDetails(){
    debugger

    let apiUrl ="";

    if(this.onboard_category=="dwn_new"){
      apiUrl="dwOnboarding/getDwnInvestmentDetails";
    }else if(this.onboard_category=="dwn_renewal"){
      apiUrl="dwSubscriptionRenewal/getDwnInvestmentDetails_dhanwaan_renewal";
    }else{
     }
     
    this.commonService.postData(apiUrl,{
      leadId:this.userData.leadID,
      ob_customer_id:this.userData.ob_customer_id

    }).subscribe((res) => {
      this.user_InvestmentDetails= res.data; 
    })
     
  }
 

  displayFn(elm) {
    debugger
    return elm ? elm.name : elm;
  }


  ShowAttention(form:any){

    if(!this.user_InvestmentDetails['investment_accepted']){
      this.serviceFactory.msgPop("Please Confirm Investment Amount." ,'warning');
      return false 
     }
    
   

    Swal.fire({ 
      title: 'Attention!',
        html: `<div style=" line-height: 26px; ">Please confirm that payment is being paid from subscriber's own account.</div>`,
     // icon: 'warning', 
      customClass: {
          confirmButton: 'mat-flat-button mat-button-base mat-primary bg-primary',
          cancelButton: 'mat-stroked-button mat-button-base ',
          container: 'modal-yes-no Modal_Delete', 
          actions: 'modal-btn-yes-no mb-4', 
          header: 'pt-4', 
      },
      width: '36em',
      showCloseButton: true,
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel' , 
      focusConfirm:false, 
      focusCancel:true,     
     }).then((result) => {
        if (result.isConfirmed) {
          if(this.onboard_category=='dwn_new'){
            this.onSubmit_Dwn(form);
          }else{
            this.onSubmit_DwnRenewal(form);
          }
           
        }
      })
  }


  onSubmit_Dwn(form){
    debugger
 

 if(!form.valid){
  return false
} 
 
    let formData = {
      leadId:this.userData.leadID,
      ob_customer_id:this.userData.ob_customer_id,
      industry:[],
      company:[],
      additional_comment:form.value.additional_comment,
      no_of_stocks_preferred_range:form.value.no_of_stocks_preferred_range,
      portfolio_restriction:this.activeReligious?.value
    }
 

    Object.values(form.value.industry).forEach((element:any) => {
       debugger
       formData.industry.push({
        id:element.id,
        reason:element.reason
      }) 
    })

    Object.values(form.value.company).forEach((element:any) => {
      debugger
      formData.company.push({
       id:element.id,
       reason:element.reason
     }) 
   })


  
   this.serviceFactory.loadingStart("body","Please wait while loading...",""); 
    this.commonService.postData('dwOnboarding/saveInvestment_dhanwaan',formData).pipe( 
      finalize(() => { 
        this.serviceFactory.loadingStop("body","");
      })
    ).subscribe((res) => {
      debugger
      this.serviceFactory.notification(res.message,res.status);
      if(res.status){
        this.agreementPopup()
       // this.dialogRef.close({res});
      }
    })
    

     
    
  }


  onSubmit_DwnRenewal(form){
    debugger





    
 if(!this.user_InvestmentDetails['investment_accepted']){
  this.serviceFactory.msgPop("Please Confirm Investment Amount." ,'warning');
  return false

 }
 
    let formData = {
      leadId:this.userData.leadID,
      ob_customer_id:this.userData.ob_customer_id,
      industry: [],
      company: [],
      portfolio_restriction:this.activeReligious?.value
    }
 

    this.serviceFactory.loadingStart("body","Please wait while loading...","");
    this.commonService.postData('dwSubscriptionRenewal/saveInvestment_dhanwaan_renewal',formData).pipe( 
      finalize(() => { 
        this.serviceFactory.loadingStop("body","");
      })
    ).subscribe((res) => {
      debugger
      this.serviceFactory.notification(res.message,res.status);
      if(res.status){
        this.agreementPopup()
       // this.dialogRef.close({res});
      }
    })
    

     
    
  }



  onChangeAccepted(event){
    debugger
    let apiUrl ="";

    if(this.onboard_category=="dwn_new"){
      apiUrl="dwOnboarding/confirmInvestment_dhanwaan";
    }else if(this.onboard_category=="dwn_renewal"){
      apiUrl="dwSubscriptionRenewal/confirmInvestment_dhanwaan_renewal";
    }else{
     }

    this.commonService.postData(apiUrl,{
      leadId:this.userData.leadID,
      investment_accepted:event.checked,
      ob_customer_id:this.userData.ob_customer_id
    }).subscribe((res) => {
      this.serviceFactory.notification(res.message,res.status);
    })
  }


  agreementPopup(){
    let dialogRef = this.dialog.open(RetailDwnAgreementPopupComponent,{
         autoFocus: false,
         disableClose: true,
         width: '1000px',
         data:{onboard_category:this.onboard_category},
         height:"92%"
     });
 
     dialogRef.afterClosed().subscribe(result => {
       console.log(`Dialog result: ${result}`);
     });
   }

   onChange_No_of_stocks(event){
     debugger
     if(event.value!='20-26'){
      this.dsclmr_no_of_stocks_preferred_range = true
     }else{
      this.dsclmr_no_of_stocks_preferred_range = false
     }
   }
 
 

}
