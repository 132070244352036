import { KeyValue } from '@angular/common';
import { Component, HostListener, isDevMode, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from "src/services/api/common.service";
import { DataFactoryService } from 'src/services/factory/data-factory.service';
import { ServiceFactory } from 'src/services/factory/service-factory.service';
 

@Component({
  selector: 'app-risk-profile',
  templateUrl: './risk-profile.component.html',
  styleUrls: ['./risk-profile.component.scss'],
  host: {'class': 'page-wrapper'} ,
})
export class RiskProfileComponent implements OnInit {
  onboard_category:string;
  userData:any
  riskFormData ={
    QnAList:{},
    riskDeclareCheckbox:false
  }
  storeSubmitRiskData = {}
  reSubmitRisk 
  allDataSoored ={}

  tooltipOptions:any = { 
     "16": "Our product is not suitable for Investment horizon of less than 1 year.", 
     "25": "Our product is not suitable for Short term returns.",
     "73": "Our product is not suitable for Investment horizon of less than 1 year.", 
     "82": "Our product is not suitable for Short term returns."
 }
WarningOptions_Selected = [];
WarningOptions_Disclaimer ="Our product is not suitable for Investment horizon of less than a year. In case you desire to subscribe any product from us, you are requested to consider long term investment goals. Please review your answer if you wish to subscribe our product. Please exit if you are not desirous of reviewing your answer or if your investment goals are for short term investment. Our products are suitable for long term investment of 1-3 years and above."
@ViewChild('riskForm') public riskForm: NgForm;
@ViewChild("dialogRiskCompareRef") dialogRiskCompareRef: TemplateRef<any>;
Ref_RiskCompare;

LowestRating_SelectdQuestions = {}
incomplete_Score;
showScore =false;
  constructor(
    private commonService: CommonService,
    private serviceFactory: ServiceFactory,
    private dataFactory: DataFactoryService,
    private router:Router,
    private route :ActivatedRoute,
    private dialog: MatDialog
  ) { 
    this.onboard_category = route.parent.snapshot.data['roles'];
    if(isDevMode()) {
      this.showScore =true
    }
     let forThis = this;
     this.dataFactory.get_userData().subscribe(res => { 
      if(res.url){
        this.userData =res;
        this.getRiskProfileQuestions(); 
      } 
     })
     this.reSubmitRisk = this.dataFactory.get_data("reSubmitRisk") ;
     if(this.reSubmitRisk){
       debugger
       this.allDataSoored['userName'] = this.reSubmitRisk.CustomerName;
       this.reSubmitRisk.QnAList.forEach(element => {
        
  
        if(this.onboard_category=="retail_new" || this.onboard_category=="retail_renewal"){
          this.riskFormData.QnAList[element.qid] = [element.option_id];
          }else if(this.onboard_category=="dwn_new" || this.onboard_category=="dwn_renewal"){
            this.riskFormData.QnAList[element.qid] = [element.answer_id];
          }else{
         }

       });
        debugger
      
     }
     
     if(this.onboard_category=="dwn_new" || this.onboard_category=="dwn_renewal"){
      this.tooltipOptions = { 
         "35": "Our product is not suitable for Investors having less than 10% risk appetite.",
         "77": "Our product is not suitable for Investment horizon of less than 1 year.", 
         "86": "Our product is not suitable for Short term returns."
      }
      this.WarningOptions_Disclaimer ="Our product is not suitable for investors having risk appetite of less than 10%. In case you desire to subscribe this product, you are requested to review and evaluate your risk appetite. Please exit if you are not desirous of reviewing your answer or if your risk appetite is not suitable to the features of our product ."
    }
  }

  ngOnInit(): void {
   debugger
    
  }

 

  getRiskProfileQuestions() {
    debugger
    
    let apiUrl ="";
    if(this.onboard_category=="retail_new"){
      apiUrl="onboarding/getRiskProfileQuestions";
    }else if(this.onboard_category=="dwn_new"){
      apiUrl="dwOnboarding/getRiskProfileQuestions_dhanwaan";
    }else if(this.onboard_category=="retail_renewal"){
      apiUrl="subscriptionRenewal/getRiskProfileQuestions_renewal";
    }else if(this.onboard_category=="dwn_renewal"){
      apiUrl="dwSubscriptionRenewal/getRiskProfileQuestions_dhanwaan_renewal";
    }else{
     }
     
    this.commonService.postData(apiUrl,{
      leadId:this.userData.leadID,
      ob_customer_id:this.userData.ob_customer_id
    }).subscribe((res) => {
      this.allDataSoored = res.data; 
    });
  }

  async onSubmit(formData){
    debugger
    this.incomplete_Score = undefined;
    
    
    if(!formData.form.controls['riskDeclareCheckbox'].value){
      formData.form.controls['riskDeclareCheckbox'].setErrors({'incorrect': true});
    }
    

    if(!formData.valid){ 
      return false
    }

    


    
    let warningOptions =[]

      let answer_null =[] 

      let answers_selected = []




      
       Object.entries(formData.value.QnAList).forEach(([key, value]) => {
       //debugger
       if(!value|| value==""){
        answer_null.push(key);
       }else{  

        if(this.onboard_category=="retail_new" || this.onboard_category=="retail_renewal"){
          answers_selected.push({option_id:value[0] , qid:Number(key)});   
          if(this.tooltipOptions[value[0]]){
            warningOptions.push(this.tooltipOptions[value[0]]);
          } 
     
         }else if(this.onboard_category=="dwn_new" || this.onboard_category=="dwn_renewal"){
          answers_selected.push({answer_id:value[0] , qid:Number(key)}); 
          if(this.tooltipOptions[value[0]]){
            warningOptions.push(this.tooltipOptions[value[0]]);
          }
         }else{
         }

        
          
        }

      });
      
       
      if(warningOptions.length>0){
          this.serviceFactory.msgPop(warningOptions[0] ,'warning');
          return false
        }


      if(answer_null.length>0){
        this.serviceFactory.msgPop('Please fill the answer of question no. '+answer_null.map(elm => elm)+' .','error');
        return false
       }

   

       
       let get_client_ip = await this.commonService.getClientipInfo();

       let apiUrl ="";
       let postData = {}
       if(this.onboard_category=="retail_new"){
         apiUrl="onboarding/saveUserQuestionaire";
         postData={
          answers_selected:answers_selected,
          lead_id:this.userData.leadID,
          client_ip:get_client_ip
         }
       }else if(this.onboard_category=="dwn_new"){
         apiUrl="dwOnboarding/saveUserQuestionaire_dhanwaan";
         postData={
          answers_selected:answers_selected,
          leadId:this.userData.leadID,
          client_ip:get_client_ip
         }
       }else if(this.onboard_category=="retail_renewal"){
        apiUrl="subscriptionRenewal/saveUserQuestionaire_renewal";
        postData={
         answers_selected:answers_selected,
         leadId:this.userData.leadID,
         client_ip:get_client_ip
        }
      }else if(this.onboard_category=="dwn_renewal"){
        apiUrl="dwSubscriptionRenewal/saveUserQuestionaire_dhanwaan_renewal";
        postData={
         answers_selected:answers_selected,
         leadId:this.userData.leadID,
         client_ip:get_client_ip
        }
      }else{

      }
      
       postData['ob_customer_id']=this.userData.ob_customer_id;
       this.commonService.postData(apiUrl,postData).subscribe((res) => {
         debugger
         this.serviceFactory.notification(res.message,res.status); 
        let isChanged = false
         if(res.data && this.onboard_category=="retail_renewal" && res.data['is_portfolio_changed']){
          this.portfolio_changed(res.data);
          isChanged = true;
         }if(res.data && this.onboard_category=="dwn_renewal" && res.data.profileChanged.is_profile_changed){
          this.portfolio_changed(res.data);
          isChanged = true;
         }else if(res.data && res.data.LowestRatingQuestions && res.data.LowestRatingQuestions.length>0){

          let allScore =0;
          Object.entries(formData.value.QnAList).forEach(([key, value]) => {
            //debugger
            let filter_qid = this.allDataSoored['qnaDataList'].filter(elm => elm.qid ===Number(key))[0].options; 
            let filter_opton = filter_qid.filter(elm => elm.optionId ===value[0])[0];  
            allScore += filter_opton.score;            
     
           });

           this.incomplete_Score = allScore;

          let lowQ = {}
          res.data.LowestRatingQuestions.forEach(element => {
            lowQ[element.qid] = element.answer_id
          });        

           this.LowestRating_SelectdQuestions = lowQ;
           this.serviceFactory.msgPop("Based on your answers, you fall under Low Risk Profile. Please revisit your Risk Profile." ,'warning');
         }else if(res.status && !isChanged){
          this.router.navigate(['../portfolio-analysis'],{ 
            queryParams: {uid: this.userData.url},
            relativeTo: this.route 
          });  
         }else{

         }

 
       });
       // 

  }
  
  onNgModelChange(qid,event){  
    debugger
    let warningOptions =[] 
    Object.entries(this.riskForm.value.QnAList).forEach(([key, value]) => {
      if(value && this.tooltipOptions[value[0]]){
       warningOptions.push(value[0]);
      } 
    })
    this.WarningOptions_Selected = warningOptions;

    if(this.onboard_category=="dwn_new" || this.onboard_category=="dwn_renewal"){
      let filter_qid = this.allDataSoored['qnaDataList'].filter(elm => elm.qid ===qid)[0].options
      let filter_opton = filter_qid.filter(elm => elm.optionId ===event.option.value)[0];  
      this.incomplete_Score += filter_opton.score; 
       if(this.incomplete_Score>20){
        this.LowestRating_SelectdQuestions = {}
       }
     }else{
     }


   


  

     
 
  }

  portfolio_changed(res){
    debugger
    let data = res;

    let retail_renewal={
      "low":[
        "Businesses selected have high degree of predictability of earnings growth and risks.",
        "Low churning strategy with targeted 10-15% downside.",
        "Portfolio selection & monitoring with focus on delivering strong yearly returns within the overall 5*5 fundamental framework."
       ] ,
      "moderate":[
        "A few businesses selected may be in \"Early Stage Growth\" Cycle. These would be characterized by greater unpredictability in their earnings growth potential; however, will have very high probability of positive earnings surprise and multiple re-rating.",
        "Moderate churning with targeted 15-20% downside.",
        "Portfolio construction is based on longer investment horizon of 3+ years rather than yearly returns."
       ] 
      };
 
      let dwn_renewal={
        "High":[
          'A few businesses selected may be in &quot;Early Stage Growth&quot; Cycle. These would be characterized by greater unpredictability in their earnings growth potential; however, will have very high probability of positive earnings surprise and multiple re-rating.',
          'High churning with targeted 15-20% downside.',
          'Portfolio construction is based on longer investment horizon of 3+ years rather than yearly returns.',
         ] ,
        "Moderate":[
          'Businesses selected have high degree of predictability of earnings growth and risks.',
          'Moderate churning strategy with targeted 10-15% downside.',
          'Portfolio selection & monitoring with focus on delivering strong yearly returns within the overall framework.'
         ]
        } 


        if(this.onboard_category=="retail_renewal"){
          data['risk_list']=retail_renewal;
          
        }else if(this.onboard_category=="dwn_renewal"){
          data['risk_list']=dwn_renewal;
          data['risk_profile']=data.profileChanged.risk_profile;
        }
      
      
      this.Ref_RiskCompare = this.dialog.open(this.dialogRiskCompareRef, {
        autoFocus: false,
       // disableClose: true,
       width: '1100px',
        data:data
      });
  }

  ContinueWith(type){
    debugger

    let api = "";

    if(this.onboard_category=="retail_renewal"){
      if(type=='old'){
        api = "subscriptionRenewal/acceptOldProfile_renewal";
      }else if(type=='new'){
        api = "subscriptionRenewal/acceptNewProfile_renewal";
      }else{
  
      }
    }else if(this.onboard_category=="dwn_renewal"){
      if(type=='old'){
        api = "dwSubscriptionRenewal/acceptOldProfile_dhanwaan_renewal";
      }else if(type=='new'){
        api = "dwSubscriptionRenewal/acceptNewProfile_dhanwaan_renewal";
      }else{
       }
    }else{

    }


    
    this.serviceFactory.loadingStart("body","Please wait while loading...","");
    this.commonService.postData(api,{
      leadId:this.userData.leadID,
      ob_customer_id:this.userData.ob_customer_id
    }).pipe( 
      finalize(() => { 
        this.serviceFactory.loadingStop("body","");
      })
    ).subscribe((res) => {
      this.serviceFactory.notification(res.message,res.status); 
         if(res.status){
          this.Ref_RiskCompare.close();
          this.router.navigate(['../portfolio-analysis'],{ 
            queryParams: {uid: this.userData.url},
            relativeTo: this.route 
          });    
         }
    });


  }

  /********************* */
  
 

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(): void {
    debugger

    let apiUrl ="";
    if(this.onboard_category=="retail_new"){
      apiUrl="onboarding/incompleteRiskInfoMail";
    }else if(this.onboard_category=="dwn_new"){
      apiUrl="dwOnboarding/incompleteRiskInfoMail_dhanwaan";
    }else if(this.onboard_category=="retail_renewal"){
      apiUrl="subscriptionRenewal/incompleteRiskInfoMail_renewal";
    }else if(this.onboard_category=="dwn_renewal"){
      apiUrl="dwSubscriptionRenewal/incompleteRiskInfoMail_dhanwaan_renewal";
    }else{
     }

     
    if(this.WarningOptions_Selected.length>0){ 
    fetch(environment.baseUrl+apiUrl, {
      keepalive: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'  
      },
      body: JSON.stringify({leadId:this.userData.leadID}),
    }); 
    }
 
 
   
  }
  
// Preserve original property order
originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
  return 0;
}

// Order by ascending property value
valueAscOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
  return a.value.localeCompare(b.value);
}

// Order by descending property key
keyDescOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
  return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
}

returnZero() {
  return 0
}

}