import { Component, isDevMode, OnInit, ViewChild } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/services/api/common.service';
import { DataFactoryService } from 'src/services/factory/data-factory.service';
import { ServiceFactory } from 'src/services/factory/service-factory.service';
import Swal from 'sweetalert2';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'D MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss'],
  host: {'class': 'page-wrapper'},
  encapsulation: ViewEncapsulation.None,
  providers: [ 
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}}
  ],
})
export class KycComponent implements OnInit {
  onboard_category:string; 
  @ViewChild('kycform') public kycform: NgForm;

  moment = moment;
  userData:any ={
    user_type:'individual'
  }   
  panVerifyed = false;

  corporateData:any = {}
  cdnUrl = environment.rnrCdn;


  getCorporateDocumentType:any ={};

  corporate_doc_list:any = {
    1:['balance_sheet','shareholding_pattern','promoters_photo','memorandum_certificate','investment_board_resolution','authorised_sign'],
    2:['balance_sheet','incorporation_certificate','trust_deed','authorised_sign','promoters_photo'],
    3:['balance_sheet','incorporation_certificate','trust_deed','authorised_sign','promoters_photo'],
    4:['incorporation_certificate','pass_book','promoters_photo'],
    5:['incorporation_certificate','managing_committee','committee_resolution','society_rules_copy']

//1) memorandum_certificate - Copies of the Memorandum and Articles of Association and certificate of incorporation.

//4) pass_book - Bank pass-book/bank statement in the name of HUF.

//5) managing_committee - List of Managing Committee members.
//5) committee_resolution - Committee resolution for persons authorised to act as authorised signatories with specimen signatures.
//5) society_rules_copy - True copy of Society Rules and Bye Laws certified by the Chairman/Secretary.

  }

  // corporate_doc_list:any = {
  //   1:[
  //     {key:"balance_sheet", label:"Balance sheets for the last 2 financial years."},
  //     {key:"balance_sheet", label:"Balance sheets for the last 2 financial years."}
  //   ]
  // }

  // corporate_doc_list:any =[
  //   {id:1, doc_list:[
  //       {key:"balance_sheet", label:"Balance sheets for the last 2 financial years."}
  //     ]
  //   }
  // ]

  // 1	Company
  // 2	Partnership
  // 3	Trust
  // 4	HUF
  // 5	Registered Society


  constructor(
      private router: Router,
      private commonService: CommonService,
      private serviceFactory: ServiceFactory,
      private dataFactory: DataFactoryService,
      private route :ActivatedRoute
    ) {
      this.onboard_category = route.parent.snapshot.data['roles']; 
      
     

     }

     ngOnInit(): void {
      
    } 



  ngAfterViewInit(): void {
    this.dataFactory.get_userData().subscribe(res => { 
      if(res.url){
        debugger
        if(!res.user_type){
          res['user_type']='individual';
        }
        this.userData =res; 
        if(res.pan_detail.pan_no && res.pan_detail.pan_no!=""){
          //this.panVerifyed = true; 
          setTimeout(()=>{ 
            
            this.kycform.form.controls.pan.setValue(res.pan_detail.pan_no);
            this.kycform.form.controls.dob.setValue(res.pan_detail.dob);  
            //this.kycform.form.controls.fullName.setValue(res.pan_detail.full_name);      
            if(res.pan_detail.pan_file){
              this.kycform.form.controls.pan_file.setValue(res.pan_detail.pan_file);
            }  

          }, 500); 
        }else{
          res.pan_detail = undefined
    
        }

        if(this.userData.user_type == 'corporate'){
          this.loadCorporateCategories();
        }
        
      } 
     })
  } 

  loadCorporateCategories() { 
    this.commonService.getData('dwOnboarding/getCorporateDocumentType').subscribe((res:any) => {
    const result = res.data.filter((elm:any) => elm.id == this.userData.corporate_doc_type)[0];
    this.getCorporateDocumentType = result; 
    console.log('document_name')
    console.log(result.document_name)
   })
 }

  async submitForm(kycform) {
    debugger
    if(!kycform.valid){
      return false
    }


    var payload = new FormData(); 
    payload.append('pan', kycform.value.pan); 

 
    let checkAge = await this.checkAge(kycform.value.dob,18);
    if(!checkAge && this.userData.user_type == "individual"){
      Swal.fire({ 
        title: 'Attention!',
        html: "Age you have filled is less than 18 years. Do you want to proceed with this?",
       // icon: 'warning', 
        customClass: {
            confirmButton: 'mat-flat-button mat-button-base mat-primary',
            cancelButton: 'mat-stroked-button mat-button-base mat-warm ',
            container: 'modal-yes-no Modal_Delete', 
            actions: 'modal-btn-yes-no mb-4', 
            header: 'pt-4', 
        },
        width: '36em',
        showCloseButton: true,
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel' , 
        focusConfirm:false, 
        focusCancel:true,     
       }).then((result) => {
          if (result.isConfirmed) {
            this.verifyPAN(payload); 
          }
        })
    }else{
      this.verifyPAN(payload); 
    }

 
  
  }


  verifyPAN(payload){
   debugger 
    if(isDevMode()){
      this.serviceFactory.notification("Your pan verification successfully.",true); 
        this.panVerifyed = true;
        setTimeout(()=>{ 
            this.kycform.form.controls.fullName.setValue("Testing_"+this.userData.leadID);  
        }, 500); 
    }else{
   this.commonService.verifyPANQuicko(payload).subscribe((res)=> {
    debugger  
    if(res.status ==="VALID"){
      this.serviceFactory.notification("Your pan verification successfully.",true);
     
      if(res.category == "Individual" && this.userData.user_type == "corporate" &&  (this.onboard_category=="dwn_new" || this.onboard_category=="dwn_renewal")){
        this.serviceFactory.notification("Please enter your company pan number.",false); 
        return false
      }
      if(res.category == "Company" && this.userData.user_type == "individual" &&  (this.onboard_category=="dwn_new" || this.onboard_category=="dwn_renewal")){
        this.serviceFactory.notification("Please enter your individual pan number.",false); 
        return false
      }

       this.panVerifyed = true;
        setTimeout(()=>{ 
           this.kycform.form.controls.fullName.setValue(res.full_name);  
        }, 500); 
       
    } else{
      this.serviceFactory.notification("Please enter valid PAN details.",false); 
        this.panVerifyed = false;
        setTimeout(()=>{ 
           this.kycform.form.controls.fullName.setValue('');  
        }, 500); 
    }
    
  })
    }

    
    
    
}

showDefName(newName:any){
  Swal.fire({ 
    title: 'Attention!',
    html: "Your name are not matched with pan data. Do you want to proceed with new data?",
   // icon: 'warning', 
    customClass: {
        confirmButton: 'mat-flat-button mat-button-base mat-primary bg-primary',
        cancelButton: 'mat-stroked-button mat-button-base ',
        container: 'modal-yes-no Modal_Delete', 
        actions: 'modal-btn-yes-no mb-4', 
        header: 'pt-4', 
    },
    width: '36em',
    showCloseButton: true,
    buttonsStyling: false,
    showCancelButton: true,
    confirmButtonText: 'Confirm',
    cancelButtonText: 'Cancel' , 
    focusConfirm:false, 
    focusCancel:true,     
   }).then((result) => {
      if (result.isConfirmed) {
        this.panVerifyed = true;
        setTimeout(()=>{
          this.kycform.form.controls.fullName.setValue(newName); 
        }, 500); 
        
      }else{
        this.panVerifyed = false;
      }
    })
}

checkAge(d,a){
  var today = new Date();
      var birthDate = new Date(d);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }
      return age >= a;
}

async sendPan(kycform) {
  debugger
  if(!kycform.valid){
    return false
  }
    let formValue =kycform.value;
    formValue['leadId'] = this.userData.leadID;
    formValue['ob_customer_id'] = this.userData.ob_customer_id;

   if(this.userData.user_type == 'corporate'){
    let corporate_array = [];  
    Object.keys(formValue.corporateData).forEach(function (key) {
      debugger
      if(formValue.corporateData[key] && formValue.corporateData[key]!=""){
        corporate_array.push({
          data_key: key,
          value:formValue.corporateData[key]
        })
      } 
  });
   formValue['corporateData'] = corporate_array;
   }

   let apiUrl ="";
   if(this.onboard_category=="retail_new"){
     apiUrl="onboarding/panverified";
   }else if(this.onboard_category=="dwn_new"){
     apiUrl="dwOnboarding/panverified_dhanwaan";
   }else if(this.onboard_category=="retail_renewal"){
    apiUrl="subscriptionRenewal/panverified_renewal";
  }else if(this.onboard_category=="dwn_renewal"){
    apiUrl="dwSubscriptionRenewal/panverified_dhanwaan_renewal";
  }else{
  }


   this.commonService.postData(apiUrl,formValue).subscribe(async (res) => {
    this.serviceFactory.notification(res.message,res.status);
    if(res.status){
       
      if(res.data && res.data['kyc_completed']){
         window.location.reload(); 
       }else{
        this.router.navigate(['../risk-profile'],{ 
          queryParams: {uid: this.userData.url},
          relativeTo: this.route 
        });
       }

          
    }
  });

    
  }

  reset(kycform) {
    debugger
    kycform.reset(); 
    this.panVerifyed = false;
   }

   async handlePanFileInput(event,ngModel){ 
    debugger
   let validFile =  await this.fileFalidation(event.target.files[0]); 
   debugger
   if(!validFile){
    debugger
     event.target.value = "";  
   }else{
        var formData = new FormData();
        formData.append("leadId", this.userData.leadID); 
        formData.append("ob_customer_id", this.userData.ob_customer_id);
        formData.append("pan_file", event.target.files[0]);
        formData.append("salesType", this.onboard_category); 
        
     

        let apiUrl ="onboarding/pancardupload";
   

        this.commonService.uploadFile(apiUrl,formData).subscribe((res) => {  
          debugger  
          if(res.status){ 
            this.kycform.form.controls.pan_file.setValue(res.pan_file); 
          }else{
            this.serviceFactory.notification(res.message,res.status); 
          }
      
         })  
   } 
  }

   async handleFileInput(event,ngModel){ 
    debugger
   let validFile =  await this.fileFalidation(event.target.files[0]); 
   debugger
   if(!validFile){
    debugger
     event.target.value = "";  
   }else{
        var formData = new FormData();
        formData.append("leadId", this.userData.leadID);
        formData.append("ob_customer_id", this.userData.ob_customer_id);
        formData.append("corp_file_type", ngModel);
        formData.append("corporate_file", event.target.files[0]);
        

        let apiUrl ="";
       if(this.onboard_category=="dwn_new"){
          apiUrl="dwOnboarding/upload_corporate_file_dhanwaan";
        }else if(this.onboard_category=="dwn_renewal"){
         apiUrl="dwSubscriptionRenewal/upload_corporate_file_dhanwaan_renewal";
       }else{
       }

        this.commonService.uploadFile(apiUrl,formData).subscribe((res) => {  
          debugger 
          
          if(res.status){ 
            this.corporateData[ngModel]=event.target.files[0].name; 
          }else{
            this.serviceFactory.notification(res.message,res.status); 
          }
      
         })  
   } 
  }
fileFalidation(file){ 
   debugger 
   let validFile = false;

  var fileSize = file.size/1024/1024;	  
  if(fileSize > 5){
      this.serviceFactory.msgPop('Maximum file size should be 5 MB.','error');
     validFile = false;
     return false
   }

   var exts = ["png","jpg","jpeg","pdf"];

   if (file) {
     var get_ext = file.name.split('.'); 
       get_ext = get_ext.reverse();  
       if ( exts.some(function(el){ return el === get_ext[0]})){
        // debugger
        validFile = true;
           return true;
           
     }else{
        this.serviceFactory.msgPop('File format is not supported. <br>Please select &nbsp; (.png, .jpg, .jpeg,.pdf) &nbsp; file  only.','error');
        validFile = false;
        return false
     }
   }
   return validFile
   
}


}