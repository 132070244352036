import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { CommonService } from 'src/services/api/common.service';
import { DataFactoryService } from 'src/services/factory/data-factory.service';
import { ServiceFactory } from 'src/services/factory/service-factory.service';
import Swal from 'sweetalert2';
import { OtpPopupComponent } from '../otp-popup/otp-popup.component';


@Component({
  selector: 'app-otp-agreement',
  templateUrl: './otp-agreement.component.html',
  styleUrls: ['./otp-agreement.component.scss']
})
export class OtpAgreementComponent implements OnInit {
  newDate = new Date().toISOString();
  leadId;
  userDetails_For_Doc = {}
  userDetailsData = []
  isDwn = false;
  isMpo = false;
  is5in5 = false;
  @ViewChild('dialogHtmlBodyForPDF') dialogHtmlBodyForPDF: ElementRef;
  constructor(
    private activeRoute: ActivatedRoute,
    private router:Router,
    private commonService: CommonService,
    private dataFactory: DataFactoryService,
    private serviceFactory: ServiceFactory,
    public dialog: MatDialog,
    
  ) { 
  debugger
  this.leadId = this.activeRoute.snapshot.queryParams.id;
   if(this.leadId){
     this.getUserAgreementDataByLeadId()
   }
    
  }

  ngOnInit(): void {
  }

  objExists(product_id) {
    debugger
    return this.userDetails_For_Doc['productDtls'].some(function(el) {
      return el.productId === product_id;
    }); 
  }
  
  

  getUserAgreementDataByLeadId(){
    this.commonService.getUserAgreementDataByLeadId(this.leadId).subscribe((res) => {
       debugger
       if(res.status){
        this.userDetails_For_Doc = res.data;
        this.userDetailsData = res.data;
        if (this.objExists(1)) {
          this.is5in5 = true;
        } 
        if (this.objExists(12)) {
          this.isMpo = true;
        }
        if (this.objExists(14)) {
          this.isDwn = true;

          let product5in5index = this.userDetails_For_Doc['productDtls'].findIndex((element) => element["productId"] == 1);
          let productMPOindex = this.userDetails_For_Doc['productDtls'].findIndex((element) => element["productId"] == 12);

          if(product5in5index>=0) {
            this.userDetails_For_Doc['productDtls'].splice(product5in5index, 1);
          }
          if(productMPOindex>=0) {
            this.userDetails_For_Doc['productDtls'].splice(productMPOindex, 1);
          }
        }
       }else{
         if(res.type=="agreementDone"){
          this.router.navigate(['/agreement-thankyou']); 
         }else{
          Swal.fire({
            buttonsStyling: false, 
            allowOutsideClick:false,
            showCloseButton: false, 
            customClass: { 
              container: 'modal-yes-no swal-msg-type',
              popup: 'modalBG',
              actions: 'modal-btn-yes-no mb-4',
              closeButton: 'btn-modal-dismiss',
              icon: 'modal-icon-top',
              confirmButton: 'mat-raised-button mat-button-base mat-primary', 
            },
              title:"Error",	
              html: res.message,
              icon:"error",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = 'https://researchandranking.com/'
              }
            })
         } 
       }

      
    })

  }


  sendOtp(){
    debugger
   
     this.serviceFactory.loadingStart("body","Please wait while loading...","");
    this.commonService.postData("existingUserAgreement/sendOtpForAgreement",{leadNo:this.leadId}).pipe(  
      finalize(() => { 
       this.serviceFactory.loadingStop("body","");
      })
    ).subscribe((res) => {
      debugger
      this.serviceFactory.notification(res.message,res.status);
      if(res.status){
         this.openOtpPopup(res)
      }else{
        
      }
   }) 
  }

  openOtpPopup(res){
    this.userDetails_For_Doc['agr_user_type'] = "old"; 

    this.userDetails_For_Doc['user_full_name'] = this.userDetails_For_Doc['user_full_name'];
    this.userDetails_For_Doc['email_id'] = this.userDetails_For_Doc['email_id'];
    this.userDetails_For_Doc['phoneNo'] = this.userDetails_For_Doc['phoneNo'] 

    let dialogRef = this.dialog.open(OtpPopupComponent,{
      autoFocus: false,
      disableClose: true,
      width: '800px',
      data:{
        res:res,
        leadId:this.leadId,
        pdfHtml:this.dialogHtmlBodyForPDF,
        date:this.newDate,
        user:this.userDetails_For_Doc
      }
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log(`Dialog result: ${result}`);
  });
  }

}
