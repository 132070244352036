 
  <header *ngIf="!counsellor_preview">
    <div class="container">
      <img src="./assets/images/equentis_logo.png" style="filter: brightness(0) invert(1);"alt="logo" />
       <span>Welcome to R&R Agreement Sign System.</span> 
       
    </div>
 </header> 


 <div class="container" 
 *ngIf="!agreementHide && userDetails_For_Doc['base_detail']" 
 [class.counsellor_preview]="counsellor_preview" [class.mt-5]="!counsellor_preview">
  
   <div  class="agrForPDF mat-elevation-z2"  > 
    <div #dialogHtmlBodyForPDF>
            <app-old-file *ngIf="without_non_advisory==0 || without_non_advisory==false" [userDetails_For_Doc]="userDetails_For_Doc" [productIds]="productIds"></app-old-file>
            <app-new-file *ngIf="without_non_advisory==1 || without_non_advisory==true" [userDetails_For_Doc]="userDetails_For_Doc" [productIds]="productIds"></app-new-file>
  
            <br>
            <div class="page-break">&nbsp;</div>
            <app-client-consent-declaration></app-client-consent-declaration>
  </div>
</div>

<div *ngIf="!counsellor_preview" class="text-center pt-5 pb-5">
  <button  mat-raised-button class="m-auto" color="primary" (click)="ShowAttention()">{{userData['legal_agreement_status']=='otp'?'Confirm And Proceed with OTP':'Confirm And Proceed To E-Sign'}}</button>
 </div> 
</div>

<div *ngIf="agreementHide" class="Something_Wrong">
  <div class="text-center">
      <mat-icon svgIcon="alert-circle" style="height: 80px;width: 80px;color: #767676;"></mat-icon>
      <div class="OopsClass">Oops! Something went wrong.</div>
      <p>{{agreementHide}}</p>
  </div>
</div>



 <!-- <button mat-raised-button *ngIf="userDetails_For_Doc['base_detail']" style="visibility: hidden;"></button> -->
 