import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'; 
import { httpInterceptorProviders } from '../http-interceptors/index';
import { DataFactoryService } from '../services/factory/data-factory.service'; 
import { NgOtpInputModule } from  'ng-otp-input';
import { AppRoutingModule,AppModuleConst } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatIconRegistry } from '@angular/material/icon';
 
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment'; 
import { LocationBackDirective } from './directive/location-back.directive';  
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
import { DemoMaterialModule } from './material-module';  
import { OtpPopupComponent } from './agreement-existing-user/otp-popup/otp-popup.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { QRCodeModule } from 'angularx-qrcode';
import { RpAcceptThankyouComponent } from './pages/rp-accept-thankyou/rp-accept-thankyou.component';
import { SharedModule } from './shared.module'; 
import { RetailDwnAgreementPopupComponent } from './agreement/retail-dwn-agreement-popup/retail-dwn-agreement-popup.component';
import { ClientConsentDeclarationComponent } from './agreement/client-consent-declaration/client-consent-declaration.component';
 





@NgModule({
  declarations: [
    AppModuleConst,
    AppComponent, 
    LocationBackDirective, 
    RetailDwnAgreementPopupComponent,
    RpAcceptThankyouComponent,
    OtpPopupComponent,
    ClientConsentDeclarationComponent
  ],
  imports: [
    NgOtpInputModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,   
    AppRoutingModule, 
    CommonModule,   
    ReactiveFormsModule,
    FormsModule, 
    DemoMaterialModule,  
    SharedModule,
    QRCodeModule,
    NgSelectModule
   // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }), 
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [httpInterceptorProviders,DataFactoryService,DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer){
    matIconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')
    );
  }
} 
