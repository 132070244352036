import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';  
import { CookieService } from 'ngx-cookie-service'; 
 
import { ServiceFactory } from '../../../services/factory/service-factory.service';
import { MatDialog } from '@angular/material/dialog'; 
import { DataFactoryService } from 'src/services/factory/data-factory.service';
import { CommonService } from 'src/services/api/common.service';
import { ClientConsentDeclarationComponent } from 'src/app/agreement/client-consent-declaration/client-consent-declaration.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  onboard_category:string; 

  GetStarted = "Great, Let’s Get Started"
  otpMsg = "Please confirm your details to receive OTP.";
  otpSended =false;


  passwordShowHide = true;
  loginForm: FormGroup;
  verifyOtpForm: FormGroup;
  submitted = false; 
  loginErrorMSG;
  userData:any
   
  @ViewChild('elm_phone_no') public elm_phone_no: ElementRef;
  
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private commonService: CommonService,
    private serviceFactory: ServiceFactory, 
    private dataFactory: DataFactoryService,
    private route: ActivatedRoute, 
    public dialog: MatDialog,
    ) {
      debugger   
      this.onboard_category = route.parent.snapshot.data['roles']; 

      this.loginForm = this.fb.group({
        email: ["", [Validators.required, Validators.email]],
        phone: ["", [Validators.required]], 
        declareCheckbox: [null, [Validators.required]], 
      });

      this.verifyOtpForm = this.fb.group({ 
        otp: ["", [Validators.required]]  
      });
    

     }

  ngOnInit() {
    this.dataFactory.get_userData().subscribe(res => {
      console.log(res);
      if(res.url){
        this.userData =res;
        this.loginForm.patchValue({
          email: res.customer_email_id,
           phone:res.customer_phone_no
        });

        setTimeout(()=>{                     
          this.elm_phone_no.nativeElement.focus()
         }, 150); 
        
         setTimeout(()=>{  
          this.elm_phone_no.nativeElement.blur()
         }, 200);
        
       
      }
      
     })

    this.loginForm.valueChanges.subscribe(x => { 
      this.loginErrorMSG = false; 
   })

  
  
  }

  trimString(name,event){
    debugger
    if(event){
      this.verifyOtpForm.controls[name].setValue(event.currentTarget.value.replace(/\s/g, ''));
    }
    
  }
 

  onSubmit() {
    debugger
 

   if(!this.loginForm.valid){
     return false
   }

    this.submitted = true;

    let apiUrl ="";
    if(this.onboard_category=="retail_new"){
      apiUrl="onboarding/sendOtp";
    }else if(this.onboard_category=="dwn_new"){ 
      apiUrl="dwOnboarding/sendOtp_dhanwaan";
    }else if(this.onboard_category=="retail_renewal"){
      apiUrl="subscriptionRenewal/sendOtp_renewal";
    }else if(this.onboard_category=="dwn_renewal"){
      apiUrl="dwSubscriptionRenewal/sendOtp_dhanwaan_renewal";
    }else{

    }
let optData = this.loginForm.value;
optData['ob_customer_id'] = this.userData.ob_customer_id;

    this.commonService.postData(apiUrl,this.loginForm.value).subscribe((res) => {
      debugger
      this.submitted = false;
      if (res.status) { 
        this.otpSended = true; 
        this.otpMsg = res.message;
        this.GetStarted = "Confirm OTP"
       }else{
            this.loginErrorMSG = res.message;
            this.otpSended = false;
       } 
      }  
    );
  }

 

  hasErrorPhone(name:any,hasAction:any){ 
    debugger
    if(!hasAction){ 
      this.loginForm.controls[name].setErrors({'incorrect': true});
    }else{
      this.loginForm.controls[name].setErrors(null);
    } 
 }
  onVerifyOtp(){
    debugger
    if(!this.verifyOtpForm.valid){
      return false
    }
    
    let apiUrl ="";
    if(this.onboard_category=="retail_new"){
      apiUrl="onboarding/verifyOtp";
    }else if(this.onboard_category=="dwn_new"){
      apiUrl="dwOnboarding/verifyOtp_dhanwaan";
    }else if(this.onboard_category=="retail_renewal"){
      apiUrl="subscriptionRenewal/verifyOtp_renewal";
    }else if(this.onboard_category=="dwn_renewal"){
      apiUrl="dwSubscriptionRenewal/verifyOtp_dhanwaan_renewal";
    }else{
      
    }
     
     this.commonService.postData(apiUrl,{
      otp: this.verifyOtpForm.value.otp,
      ob_customer_id:this.userData.ob_customer_id,
      leadId:this.userData.leadID
    }).subscribe((res) => {
      debugger 
      this.serviceFactory.notification(res.message,res.status);
      if (res.status) { 
        this.router.navigate(['../kyc'],{ 
          queryParams: {uid: this.userData.url},
          relativeTo: this.route 
        });
       }else{
            this.loginErrorMSG = res.message; 
       } 
      }  
    );

  }
  
  getEventVal(event:any){
    debugger
     return event.target.value
  }
  

  declareOnChange(event:any,dialogDeclarationRef: TemplateRef<any>){
    debugger
    if(event.checked){
      let dialogRef = this.dialog.open(dialogDeclarationRef,{
        autoFocus: false, 
        disableClose: true,
        width: '1000px', 
        height:"92%"
    });

      dialogRef.afterClosed().subscribe(result => {
        
      });

    }
  }
}
