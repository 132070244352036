import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/services/api/common.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {
  userData:any
  constructor(
    private commonService: CommonService,
    private route:ActivatedRoute,
  ) { 
    // let uid = route.snapshot.queryParams['uid'];
    // let  checkIfUserNot = this.commonService.getFetchCustomer(uid);
    // if(checkIfUserNot['status']){
      
    // }


    
    
  }

  ngOnInit(): void {
    
  }

}
