<div class="payment_body pb-5">
    <header>
        <div class="container">
            <img src="./assets/images/equentis_logo.png" style="filter: brightness(0) invert(1);"alt="logo" />
            <span>Register your e-Mandate for your upcoming payments</span> 
        </div>
    </header>


    <div class="container mt-4">

        

 

        <h1 class="h1" *ngIf="if_enach_discount">
            Congratulations!!! Additional  <mat-icon svgIcon="currency-inr" style="height: 22px;position: relative;top: 4px;"></mat-icon>{{if_enach_discount}}/- discount applied 
            <ng-container *ngIf="onboard_category=='pmp_lt' || onboard_category=='dwn_lt'">
                on the last tranche if you'll successfully pay all the tranches through eNACH
            </ng-container>
            .
        </h1>

        <h1 class="h1" *ngIf="!if_enach_discount">
            e-Mandate Details
        </h1>
         
        <P>Kindly complete the eMandate process to avail it. It will not take more than 55 Seconds to complete the Process.</P>
        
       <div class="E_Nach_Details_Box">
        
        <form #ElmEnachForm="ngForm" (ngSubmit)="onSubmit(ElmEnachForm)" [mustMatch]="['account_number', 'confirm_account_number']" class="disabled_fieldset">
            
        
        <h2 class="h2">Customer Details</h2>
        <div class="Customer_Details_box">
            <fieldset [disabled]="true">
                <div class="row">
                    <div class="col-md-3">
                        <label class="input-label-text">Full Name</label>
                        <mat-form-field class="outline-yes-md mat-field-block" appearance="outline">
                            <input type="text" matInput name="customer_name" required [ngModel]="storePaymentData['customer_name']">
                        </mat-form-field> 
                    </div>
                    <div class="col-md-3" *ngIf="storePaymentData['customer_phone_no']">
                        <label class="input-label-text">Phone No.</label>
                        <mat-form-field  class="outline-yes-md mat-field-block" appearance="outline">            
                            <input matInput *ngIf="!storePaymentData['customer_phone_no'].includes('+91-')" [value]="storePaymentData['customer_phone_no']"> 
                            <input matInput *ngIf="storePaymentData['customer_phone_no'].includes('+91-')" name="mobile_no" required [ngModel]="storePaymentData['customer_phone_no']"> 

                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <label class="input-label-text">Product</label>
                        <mat-form-field class="outline-yes-md mat-field-block" appearance="outline">
                            <input matInput name="product" required [ngModel]="storePaymentData['product_name']"> 
                        </mat-form-field> 
                    </div>

                    <div class="col-md-3">
                        <label class="input-label-text">Email Id</label>
                        <mat-form-field class="outline-yes-md mat-field-block" appearance="outline">
                            <input matInput name="customer_email_id" required [ngModel]="storePaymentData['customer_email_id']"> 
                        </mat-form-field> 
                    </div>
                    
                </div>
           </fieldset>
       </div>

        <hr>

        <h2 class="h2">Bank Details</h2>
 

    <div class="Bank_Details_box"> 

        <div class="row">
             <div class="col-md-6">
                <div class="form-group mb-0">
                    <label class="input-label-text">Authentication Mode<em class="text-danger">*</em></label>
                    <div class="ng-select-group">
                        <ng-select [items]="modePayment_list" (change)="onChangeAuthenticationType($event,bankSelected)"
                        bindLabel="name" 
                        bindValue="id" 
                        required
                        name="authentication_mode" ngModel
                        #modeOfPayment="ngModel" 
                        [clearable]="!modeOfPayment.invalid"
                        [ngClass]="{ 'is-invalid':(ElmEnachForm.submitted || modeOfPayment.touched) && modeOfPayment.invalid }"
                        >
                        </ng-select>
                        <span *ngIf="ElmEnachForm.submitted && modeOfPayment.invalid" class="mat-error" style=" margin-top: 1px; ">This field is required.</span>
                       </div>
                </div>
        
                <div class="form-group mb-0">
                    <label class="input-label-text">Bank Name<em class="text-danger">*</em></label>
                    <div class="ng-select-group"> 
                        <ng-select [items]="bankFilterList"
                        bindLabel="bank_name"  
                        required 
                        name="bank_selected" 
                        ngModel
                        #bankSelected="ngModel" 
                        [clearable]="!bankSelected.invalid"
                        [ngClass]="{ 'is-invalid':(ElmEnachForm.submitted || bankSelected.touched) && bankSelected.invalid }"
                        >
                        </ng-select>
                        <span *ngIf="ElmEnachForm.submitted && bankSelected.invalid" class="mat-error" style=" margin-top: 1px; ">This field is required.</span>
                     </div>
                </div>
        
                <div class="form-group mb-0">
                    <label class="input-label-text">Account Type<em class="text-danger">*</em></label>
                    <div class="ng-select-group">
                        <ng-select [items]="accountType_list"
                        bindLabel="name" 
                        bindValue="code"
                        required
                        name="account_type" ngModel
                        #accountType="ngModel" 
                        [clearable]="!accountType.invalid"
                        [ngClass]="{ 'is-invalid':(ElmEnachForm.submitted || accountType.touched) && accountType.invalid }"
                        >
                        </ng-select>
                        <span *ngIf="ElmEnachForm.submitted && accountType.invalid" class="mat-error" style=" margin-top: 1px; ">This field is required.</span>
                       </div> 
                </div>

                <div class="form-group mb-0">
                    <label class="input-label-text">Account Holder Name<em class="text-danger">*</em></label>
                    <mat-form-field class="outline-yes-md mat-field-block" appearance="outline">
                        <input matInput required name="account_holder_name" ngModel> 
                        <mat-error>This field is required.</mat-error>
                    </mat-form-field> 
                </div>

 

             </div>



             <div class="col-md-6">
                <div class="form-group mb-0">
                    <label class="input-label-text">Bank Account No.<em class="text-danger">*</em></label>
                    <mat-form-field class="outline-yes-md mat-field-block" appearance="outline">
                        <input type="text" matInput required name="account_number" ngModel
                        (keydown.space)="$event.preventDefault();"
                        onCopy="return false"
                        onDrag="return false"
                        onDrop="return false"> 
                        <mat-error>This field is required.</mat-error>
                    </mat-form-field> 
                </div>
        
                <div class="form-group mb-0">
                    <label class="input-label-text">Confirm Bank Account No.<em class="text-danger">*</em></label>
                    <mat-form-field class="outline-yes-md mat-field-block" appearance="outline">
                        <input type="text" matInput required name="confirm_account_number" ngModel 
                        (keydown.space)="$event.preventDefault();"
                        onCopy="return false"
                        onDrag="return false"
                        onDrop="return false"
                        onPaste="return false"> 
                        <mat-error *ngIf="ElmEnachForm.submitted && ElmEnachForm.form.controls.confirm_account_number?.invalid">
                            <span *ngIf="ElmEnachForm.form.controls.confirm_account_number.errors?.required">This field is required.</span>
                            <span *ngIf="ElmEnachForm.form.controls.confirm_account_number.errors?.mustMatch">Confirm account number must match.</span> 
                        </mat-error>
                    </mat-form-field> 
                </div>
        
                <div class="form-group mb-0">
                    <label class="input-label-text">IFSC Code<em class="text-danger">*</em></label>
                    <mat-form-field class="outline-yes-md mat-field-block" appearance="outline">
                        <input matInput required name="ifsc_code" ngModel pattern="^[A-Za-z]{4}[a-zA-Z0-9]{7}$"> 
                        <mat-error>This field is required.</mat-error>
                    </mat-form-field> 
                </div>

                <div class="form-group mb-0" *ngIf="storePaymentData['customer_phone_no'] && !storePaymentData['customer_phone_no'].includes('+91-')">
                    <label class="input-label-text">Mobile No. (Same as bank)<em class="text-danger">*</em></label>
                    <mat-form-field class="outline-yes-md mat-field-block" appearance="outline">
                        <input matInput required name="mobile_no" ngModel>  
                        <mat-error>This field is required.</mat-error>
                    </mat-form-field> 
                </div>
                
             </div>

        </div>

         
         


    </div>
      
    <hr>

    
    <h2 class="h2">Upcoming Payment Details</h2>
    <div class="Payment_Details_box mb-5">
        <div class="mat-table mat-table-static">  
            <div class="mat-header-row" style="background: #fbfbfb;">
                <div class="mat-header-cell text-center cell_id" style="min-width: 80px;max-width: 80px; color: #000;">S No.</div>
                <div class="mat-header-cell text-center" style=" color: #000;">Payment Date</div>  
                <div class="mat-header-cell text-center" style=" color: #000;">Actual Payable (Inc. of GST)</div>
                <div class="mat-header-cell text-center" style=" color: #000;">Net Payable Inc. of GST(Discounted)</div>
            </div> 
            <div class="mat-row" *ngFor="let item of storePaymentData['installment']; index as i" style="border: 1px solid #E6E7E9!important;min-height: 50px;">
                <div class="mat-cell text-center cell_id" style="min-width: 80px;max-width: 80px; ">{{i+1}}</div>
                <div class="mat-cell text-center">{{item.schedule_date | date : 'dd LLL yyyy'}}</div>
               
                <div class="mat-cell text-center">

                
                    


                    <ng-container *ngIf="onboard_category!='pmp_lt' && onboard_category!='dwn_lt'">
                      <span  style="display: inline-flex; align-items: center; font-weight: 600;" [class.discounted_amount]="if_enach_discount">
                        <mat-icon svgIcon="currency-inr" style="height: 14px; width: 14px; line-height: 14px;"></mat-icon> 
                         {{(item.base_amount+getNumber(item.base_gst)) | number:'1.0-1'}} 
                      </span>
                      <span *ngIf="if_enach_discount" style=" display: block; font-size: 12px; font-weight: 600;">({{item.base_amount | number:'1.0-1'}} + {{item.base_gst | number:'1.0-1'}})</span>
                    </ng-container>

                    <ng-container *ngIf="onboard_category=='pmp_lt' || onboard_category=='dwn_lt'">
                        <span style="display: inline-flex; align-items: center; font-weight: 600;">
                            <mat-icon svgIcon="currency-inr" style="height: 14px; width: 14px; line-height: 14px;"></mat-icon> 
                             {{item.amount}} 
                          </span>
                    </ng-container>
                     
                </div>

                <div class="mat-cell text-center">

                    <ng-container *ngIf="onboard_category!='pmp_lt' && onboard_category!='dwn_lt'">
                        <span  style="display: inline-flex; align-items: center; font-weight: 600;">
                        <mat-icon svgIcon="currency-inr" style="height: 14px; width: 14px; line-height: 14px;"></mat-icon> 
                        
                        <ng-container *ngIf="if_enach_discount">
                            {{item.amount | number:'1.0-1'}} 
                        </ng-container>

                        <ng-container *ngIf="!if_enach_discount">
                            {{(item.base_amount+getNumber(item.base_gst)) | number:'1.0-1'}} 
                        </ng-container>
                        
                        
                      </span>
                      <span *ngIf="if_enach_discount" style=" display: block; font-size: 12px; font-weight: 600;">({{item.discounted_amount | number:'1.0-1'}} + {{item.new_gst | number:'1.0-1'}})</span>
                    </ng-container>

                    <ng-container *ngIf="onboard_category=='pmp_lt' || onboard_category=='dwn_lt'">
                        <span style="display: inline-flex; align-items: center; font-weight: 600;">
                            <mat-icon svgIcon="currency-inr" style="height: 14px; width: 14px; line-height: 14px;"></mat-icon> 
                             {{item.amount}} 
                          </span>
                    </ng-container>
                     
                </div>

                <!-- <div class="mat-cell text-center">
                    <span style="display: inline-flex; align-items: center;">
                      <mat-icon svgIcon="currency-inr" style="height: 14px; width: 14px; line-height: 14px;"></mat-icon> {{item.amount}}
                   </span>
               </div> -->
              </div>
        </div> 
    </div>

 

    <div class="TMC_Details_box">
        <mat-checkbox color="primary" name="confirm_tmc" [ngModel]="true" required #confirmTMC="ngModel" [ngClass]="{ 'is-invalid': ElmEnachForm.submitted && confirmTMC.invalid }"></mat-checkbox>
        <span>I agree and authorize the User entity/ Corporate to debit my account, based on the instruction as agreed and authorized by me. I have understood that I am authorized to cancel/ amend this mandate by appropriately communicating the cancellation/ amendment request to user entity/ Corporate where I have authorized the debit.</span>
    </div>
    
   


    <div class="text-center mt-4 mb-3">
        <button type="submit" mat-raised-button color="primary" style=" min-width: 120px; ">
            Submit
        </button>
 
      </div>

</form>

    </div>
    </div>


    </div>