import {NgModule} from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GktTelInput } from './directive/phone-input.directive';
import { CustomMinDirective } from './directive/custom-min-validator.directive';
import { CustomMaxDirective } from './directive/custom-max-validator.directive';
import { MustMatchDirective } from './directive/must-match.directive';


 
@NgModule({
    imports:[CommonModule,FormsModule ],
    exports: [
      GktTelInput, 
      CustomMinDirective,
      CustomMaxDirective,
      MustMatchDirective
    ],
    declarations: [
      GktTelInput,
      CustomMinDirective,
      CustomMaxDirective,
      MustMatchDirective
       
    ]
  })
  export class SharedModule {}