import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-old-file',
  templateUrl: './old-file.component.html',
  styleUrls: ['./old-file.component.scss']
})
export class OldFileComponent implements OnInit {
  @Input() userDetails_For_Doc: any;
  @Input() productIds: any;
  constructor() { }

  ngOnInit(): void {
  }

}
