import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; 
 


import { Page404Component } from './page404/page404.component';   
import { KycComponent } from './pages/kyc/kyc.component';
import { PaymentSuccessComponent } from './pages/payment-success/payment-success.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { PortfolioAnalysisComponent } from './pages/portfolio-analysis/portfolio-analysis.component';
import { RiskProfileComponent } from './pages/risk-profile/risk-profile.component';
import { RpAcceptThankyouComponent } from "./pages/rp-accept-thankyou/rp-accept-thankyou.component";
 


import { OtpAgreementComponent } from './agreement-existing-user/otp-agreement/otp-agreement.component';
import { OtpThankyouComponent } from './agreement-existing-user/otp-thankyou/otp-thankyou.component';

 
 
import { RetailOnboardingComponent } from './layout/retail-onboarding/retail-onboarding.component';
import { DwnOnboardingComponent } from './layout/dwn-onboarding/dwn-onboarding.component';
import { LoginComponent } from './pages/Login/login.component';
import { InvestmentDetailsComponent } from './pages/investment-details/investment-details.component';
import { RetailRenewalComponent } from './layout/retail-renewal/retail-renewal.component';
import { DwnRenewalComponent } from './layout/dwn-renewal/dwn-renewal.component';
 
import { RetailPmpComponent } from './layout/retail-pmp/retail-pmp.component';
import { DwnPmpComponent } from './layout/dwn-pmp/dwn-pmp.component';


import { DwnLtAgreementComponent } from './agreement/dwn-lt-agreement/dwn-lt-agreement.component';
import { RetailLtAgreementComponent } from './agreement/retail-lt-agreement/retail-lt-agreement.component';
import { EnachFormComponent } from './enach-form/enach-form.component';
import { OldFileComponent } from './agreement/retail-lt-agreement/old-file/old-file.component';
import { NewFileComponent } from './agreement/retail-lt-agreement/new-file/new-file.component';
import { NewFileDwnComponent } from './agreement/dwn-lt-agreement/new-file-dwn/new-file-dwn.component';
import { OldFileDwnComponent } from './agreement/dwn-lt-agreement/old-file-dwn/old-file-dwn.component';



 
const routes: Routes = [ 

 



 
  
  {path: 'otpagreement', component: OtpAgreementComponent ,data:{title: 'Otp Agreement'}},
  {path: 'agreement-thankyou', component: OtpThankyouComponent ,data:{title: 'Thank You'}},
  
/***************** old User Master  Agreement  */

 
/***************** dwn_lt/ DWN PMP   */
 
{
  path: 'lt-esign', component: DwnPmpComponent,  
  data: { roles: "dwn_lt"},  
  children: [   
    {path: 'esign', component: DwnLtAgreementComponent },  
    {path: 'payment', component: PaymentComponent},
    {path: 'enach', component: EnachFormComponent},
    {path: 'payment-success', component: PaymentSuccessComponent},
  ], 
}, 
 


/***************** pmp_lt/ Retail PMP  */
 
{
  path: 'lt-premium-esign', component: RetailPmpComponent,  
  data: { roles: "pmp_lt"},  
  children: [   
    {path: 'esign', component: RetailLtAgreementComponent}, 
    {path: 'payment', component: PaymentComponent},
    {path: 'enach', component: EnachFormComponent},
    {path: 'payment-success', component: PaymentSuccessComponent},
  ], 
}, 
 



/******** Start Retail Onboarding**********/

{
  path: '', component: RetailOnboardingComponent,  
  data: { roles: "retail_new"},  
  children: [   
    {path: 'login', component: LoginComponent}, 
    {path: 'kyc', component: KycComponent},  
    {path: 'risk-profile', component: RiskProfileComponent},
    {path: 'portfolio-analysis', component: PortfolioAnalysisComponent}, 
    {path: 'payment', component: PaymentComponent},
    {path: 'enach', component: EnachFormComponent},
    {path: 'rp-accept-thankyou', component: RpAcceptThankyouComponent},   
    {path: 'payment-success', component: PaymentSuccessComponent},
  ], 
}, 

/******** Start DWN Onboarding**********/
{
  path: 'renewal', component: RetailRenewalComponent,  
  data: { roles: "retail_renewal"},  
  children: [   
    {path: 'login', component: LoginComponent}, 
    {path: 'kyc', component: KycComponent},  
    {path: 'risk-profile', component: RiskProfileComponent},
    {path: 'portfolio-analysis', component: PortfolioAnalysisComponent}, 
    {path: 'payment', component: PaymentComponent},
    {path: 'enach', component: EnachFormComponent},
    {path: 'rp-accept-thankyou', component: RpAcceptThankyouComponent},   
    {path: 'payment-success', component: PaymentSuccessComponent},
  ], 
}, 


/******** Start DWN Onboarding**********/
{
  path: 'dwn', component: DwnOnboardingComponent,  
  data: { roles: "dwn_new"},  
  children: [   
    {path: 'login', component: LoginComponent}, 
    {path: 'kyc', component: KycComponent},  
    {path: 'risk-profile', component: RiskProfileComponent},
    {path: 'portfolio-analysis', component: PortfolioAnalysisComponent}, 
    {path: 'investment-details', component: InvestmentDetailsComponent}, 
    {path: 'payment', component: PaymentComponent},
    {path: 'enach', component: EnachFormComponent},
    {path: 'rp-accept-thankyou', component: RpAcceptThankyouComponent},   
    {path: 'payment-success', component: PaymentSuccessComponent},
  ], 
},

/******** Start DWN Onboarding**********/
{
  path: 'dwn-renewal', component: DwnRenewalComponent,  
  data: { roles: "dwn_renewal"},  
  children: [   
    {path: 'login', component: LoginComponent}, 
    {path: 'kyc', component: KycComponent},  
    {path: 'risk-profile', component: RiskProfileComponent},
    {path: 'portfolio-analysis', component: PortfolioAnalysisComponent}, 
    {path: 'investment-details', component: InvestmentDetailsComponent}, 
    {path: 'payment', component: PaymentComponent},
    {path: 'enach', component: EnachFormComponent},
    {path: 'rp-accept-thankyou', component: RpAcceptThankyouComponent},   
    {path: 'payment-success', component: PaymentSuccessComponent},
  ], 
},



  { path: '**', component: Page404Component,data:{roles: null, title: 'Onboarding'} },


  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const AppModuleConst = [ 
  Page404Component,
  LoginComponent,

  RetailOnboardingComponent,
  RetailRenewalComponent,
  DwnOnboardingComponent,
  DwnRenewalComponent,

  RetailPmpComponent,
  DwnPmpComponent,

  KycComponent,
  RiskProfileComponent,
  PortfolioAnalysisComponent,
  InvestmentDetailsComponent,
  PaymentComponent, 
  PaymentSuccessComponent,
  OtpAgreementComponent,
  OtpThankyouComponent,
  DwnLtAgreementComponent,   
  RetailLtAgreementComponent, 
  OldFileComponent,
  NewFileComponent,

  NewFileDwnComponent,
  OldFileDwnComponent,

  EnachFormComponent
];
 