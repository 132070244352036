 
<mat-dialog-content>
    <!-- <input type="file" (change)="eSign5digital($event)" placeholder="Upload file" > -->
     <div class="text-center mb-4">
    <img src="./assets/images/otp-verification_img.png" style="max-height: 140px;" />
    </div>

    <div class="Otp_MSG_Show">
      <h2>OTP Verification</h2>
      <p> Please enter the 4-digit verification code sent to <strong>{{otpSended_Data['email']}}</strong> and  <strong>{{otpSended_Data['phone']}}.</strong></p>
    </div>

    <div class="otp-input-body">
    <ng-otp-input  #ngOtpInput (onInputChange)="onOtpChange($event)"  [config]="OtpConfig"></ng-otp-input> 
    </div>

      <div class="text-center">
      <button mat-button class="reSendOtp"  (click)="reSendOtp()" >Resend OTP</button>
      </div>


    <div class="text-center mt-4 mb-3">
      <button mat-stroked-button mat-dialog-close  class="mr-3" style=" min-width: 90px; ">Cancel</button>
      <button mat-flat-button color="primary" (click)="submitOtp()" style=" min-width: 90px; ">Submit</button>
     
    </div>
 
</mat-dialog-content>


 