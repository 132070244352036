import { Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/services/api/common.service';
import { DataFactoryService } from 'src/services/factory/data-factory.service';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { ServiceFactory } from 'src/services/factory/service-factory.service';  
import htmlToPdfmake from "html-to-pdfmake"
import { finalize } from 'rxjs/operators'; 

pdfMake.vfs = pdfFonts.pdfMake.vfs; 
import { environment } from 'src/environments/environment'; 
import Swal from 'sweetalert2';
import { OtpPopupComponent } from 'src/app/agreement-existing-user/otp-popup/otp-popup.component';
import { MatDialog } from '@angular/material/dialog';
 

@Component({
  selector: 'app-retail-lt-agreement',
  templateUrl: './retail-lt-agreement.component.html',
  styleUrls: ['./retail-lt-agreement.component.scss']
})
export class RetailLtAgreementComponent implements OnInit { 
  counsellor_preview:any;
  onboard_category:string;
  userData:any;
  userDetails_For_Doc:any = {}; 
  productIds:any=1
  
  without_non_advisory:any;
 
 
 
  @ViewChild('dialogHtmlBodyForPDF') dialogHtmlBodyForPDF: ElementRef; 
  agreementHide:any=false;

  constructor(
    private commonService: CommonService,
    private dataFactory: DataFactoryService, 
    private serviceFactory: ServiceFactory, 
    private route :ActivatedRoute,
    public dialog: MatDialog, 
  ) {  

    debugger
    this.onboard_category = route.parent.snapshot.data['roles']; 
     
    this.counsellor_preview = this.route.snapshot.queryParams.counsellor_preview; // counsellor_preview for trackerr side

    if(this.counsellor_preview){
      this.getUserAgreementDataByLTId(this.counsellor_preview)
    } 

  }

  ngAfterViewInit(): void {
    this.dataFactory.get_userData().subscribe(res => { 
      if(res.url){
        debugger 
        this.userData = res; 
        this.without_non_advisory = res.without_non_advisory
        if(res.current_status=="sent" && !this.counsellor_preview){
          this.getUserAgreementDataByLTId(res.user_id)
        } 
        if(res.esignData && res.esignData.errormessage){
          this.serviceFactory.msgPop(res.esignData.errormessage ,'error');
        }
        
      } 
     })
  } 

  

  ngOnInit(): void {
    
  }
 

getUserAgreementDataByLTId(id) {
  this.serviceFactory.loadingStart("body","Please wait while loading...",""); 
  this.commonService.postData(`dwLTAgreement/ltfetchagreementdetail`,{user_id:id}).pipe( 
    finalize(() => { 
      this.serviceFactory.loadingStop("body","");
    })
  ).subscribe((res) => {

    if(!res.status){
      this.agreementHide = res.message; 
      return
    }
    let data = res.data;

    this.without_non_advisory = data.base_detail?.without_non_advisory 

    if(this.without_non_advisory===true){ 
      let advTranch = []; 
      let oneYAdv = []; 
      let year = 1;
       

      let oneYamount  = 0;
      let oneYamount_with_gst  = 0;
      let oneYgst = 0;

      data.adv_installment.forEach((elm:any,i:any) => {
         
        if(year!=elm.tranch_year){
          year = elm.tranch_year; 
          advTranch.push({
            adv_tranch:oneYAdv, 
            amount:oneYamount, 
            amount_with_gst:oneYamount_with_gst, 
            gst:oneYgst,   
          })
          oneYAdv = [];
          oneYamount  = 0;
          oneYamount_with_gst  = 0;
          oneYgst = 0;
        }
     

        oneYAdv.push(elm);
        oneYamount =oneYamount+Number(elm.amount);
        oneYamount_with_gst =oneYamount_with_gst+Number(elm.amount_with_gst);
        oneYgst =oneYgst+Number(elm.gst);

        if(data.adv_installment.length==(i+1)){
          advTranch.push({
            adv_tranch:oneYAdv, 
            amount:oneYamount, 
            amount_with_gst:oneYamount_with_gst, 
            gst:oneYgst,  
          })
        }
        
 
      });
     
      data['year_by_installment'] = advTranch;
    }

   
    data.base_detail['existing_product'] = data.base_detail.existing_product.replace(' / Informed InvestoRR','');
    
    data.base_detail['existing_product'] = data.base_detail.existing_product.replace('5in5','5in5 Strategy');


     this.productIds=data.base_detail.existing_product_ids_without_II;
     this.userDetails_For_Doc = data;  
     
   });


  
}


ShowAttention(){
  Swal.fire({ 
    title: 'Attention!',
    html: `<div style=" line-height: 26px; ">Please confirm that payment is being paid from subscriber's own account.</div>`,
    // icon: 'warning', 
    customClass: {
        confirmButton: 'mat-flat-button mat-button-base mat-primary bg-primary',
        cancelButton: 'mat-stroked-button mat-button-base ',
        container: 'modal-yes-no Modal_Delete', 
        actions: 'modal-btn-yes-no mb-4', 
        header: 'pt-4', 
    },
    width: '36em',
    showCloseButton: true,
    buttonsStyling: false,
    showCancelButton: true,
    confirmButtonText: 'Confirm',
    cancelButtonText: 'Cancel' , 
    focusConfirm:false, 
    focusCancel:true,     
   }).then((result) => {
    if (result.isConfirmed) {
      if(this.userData.legal_agreement_status=='otp'){ 
        this.sendOtp() 
      }
      else{
        this.eSignPdfGen() 
      }
       
    }
    })
}

sendOtp(){
  debugger 
 
   this.serviceFactory.loadingStart("body","Please wait while loading...","");
  this.commonService.postData('dwLTAgreement/sendotponmobileandemailforlt',{emailId:this.userData.customer_email_id}).pipe( 
    finalize(() => { 
     this.serviceFactory.loadingStop("body","");
    })
  ).subscribe((res) => {
    debugger
    this.serviceFactory.notification(res.message,res.status);
    if(res.status){
       this.openOtpPopup(res)
    }else{
      
    }
 }) 
}

openOtpPopup(res){

  this.userDetails_For_Doc['user_full_name'] = this.userDetails_For_Doc.base_detail['full_name'];
  this.userDetails_For_Doc['email_id'] = this.userDetails_For_Doc.base_detail['email'];
  this.userDetails_For_Doc['phoneNo'] = this.userDetails_For_Doc.base_detail['phone'] 
  
   
      
      let dialogRef = this.dialog.open(OtpPopupComponent,{
        autoFocus: false,
        disableClose: true,
        width: '800px',
        data:{ 
          res:res,
          leadId:this.userData.leadID,
          user_id:this.userData.user_id,
          pdfHtml:this.dialogHtmlBodyForPDF,
          date:this.userDetails_For_Doc['eSignDate']?this.userDetails_For_Doc['eSignDate']:new Date(),
          user:this.userDetails_For_Doc,
          onboard_category:this.onboard_category
        }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
    }
    
eSignPdfGen(){
  //alert(JSON.stringify(this.userDetails_For_Doc));
  debugger
  let forThis = this;
  this.serviceFactory.loadingStart(".mat-dialog-container","Please wait while loading...","");
      
  let option = {
    "tableAutoSize":true
  }    
  var innerHTML = htmlToPdfmake(this.dialogHtmlBodyForPDF.nativeElement.innerHTML,option); 
     // Add page breaks to the PDFMake content
     innerHTML.forEach((element, index) => {
      if (element.style && element.style.indexOf('page-break') !== -1) {
        innerHTML[index] = { text: '', pageBreak: 'before' };
      }
    });
    
  var dd = {
    pageSize: 'A4',
    pageMargins: [ 40, 40, 40, 70 ],
    content:[
     {text:"Investment Advisory Agreement", alignment: 'center',fontSize: 16, bold: true,margin: [0, 0, 0, 0]},
     { 
      canvas: [
       {
        type: 'line',
        x1: 0, y1: 0,
        x2: 100, y2: 0,
        lineWidth: 2,
        color: '#001e5a',
       },
     ],
     margin: [0, 0, 0, 40],
     alignment: 'center'
     },
     
  innerHTML,
    ],
    defaultStyle: {
      fontSize: 10,
      bold: false,
      lineHeight:1.2
    },

    footer: function(currentPage, pageCount) { 
      debugger
      // Remove footer on the last page
      if (currentPage === pageCount || currentPage === pageCount - 1) {
          return {};
      }
      else{
        return  [
          { image:forThis.dataFactory.signImg_of_maish,
           alignment: 'right',width:250,margin: [10, 10, 10, 10]},
          // {text:"Marathon Futurex, A-603 6th Floor, Mafatlal Mills Compound, N M Joshi Marg, Lower Parel East. Mumbai 400013", alignment: 'center',fontSize: 11, color: '#444',bold: false},
        ]
      } 
    }
  };

  const pdfDocGenerator = pdfMake.createPdf(dd);
  //pdfMake.createPdf(dd).download("agreement.pdf");
  console.log(pdfDocGenerator);
  pdfDocGenerator.getBlob(async (blob) => {
    debugger 
    //alert('ssss1')
   var file = new File([blob], "RNR_eSigned_Agreement.pdf", {
    type: "application/pdf",
    });
    //alert('ssss2')
     this.eSign5digital(file);
});

  
}

async eSign5digital(fileList) {
  debugger  

 let responseUrl = environment.hostName+"lt-premium-esign/?uid="+this.userData.url;
 
 var payload_upload = new FormData(); 
 payload_upload.append('file', fileList); 
 payload_upload.append('user', JSON.stringify(this.userData));
 payload_upload.append('responseUrl', responseUrl); 
 payload_upload.append('fullName', this.userDetails_For_Doc.base_detail['full_name']); 

 this.commonService.postData('eSign/uploadDocument',payload_upload).subscribe((res)=> {
   debugger
    if(res.status){
      window.location.href =  res.data.url;
     } 
   }); 
  }


public blobToFile = (theBlob: Blob, fileName:string): File => {
    var b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
}

  removeGST(val){
    var get_GST = (Number(val) * 100)/118;  
    return get_GST; 
  }
  getGST(val){
     var get_GST = (Number(val) * 18)/100;
     return get_GST
  }

  getBase64ImageFromURL(url) { 
    return new Promise((resolve, reject) => { 
      var img = new Image(); 
      img.setAttribute("crossOrigin", "anonymous"); 
      img.onload = () => { 
        var canvas = document.createElement("canvas"); 
        canvas.width = img.width; 
        canvas.height = img.height; 
        var ctx = canvas.getContext("2d"); 
        ctx.drawImage(img, 0, 0); 
        var dataURL = canvas.toDataURL("image/png"); resolve(dataURL); 
      }; 
      img.onerror = error => { 
        reject(error); 
      }; 
      img.src = url; 
    }); 
  }

  onCheckCounsellorPreview(event:any){
    debugger 
    window.parent.postMessage({page_scrollEnd:event.valid}, "*"); 
  }


}
