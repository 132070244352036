<div class="thk-wrapper">
	<header>
		<div class="container">
			<div class="d-flex justify-content-between align-items-center flex-wrap">
				<div>
					<a href="javascript:void(0)">
						<img src="./assets/images/equentis_logo.png" style=" height: 43px; " alt="LOGO">
					</a>
				</div>
				<div>
					<span class="h-txt">SEBI Registration No.: INA000003874</span>
				</div>
			</div>
		</div>
	</header>
	<div class="container">
		<div class="text-center">
			<i><img src="./assets/images/success.png" alt="Image"></i>
			<h1 class="hng mt-3">Thank You</h1>
			<p class="h-txt">You have completed your payment successfully. Our support team will get in touch with you within 48hrs.</p>
			
            <a mat-flat-button href="http://researchandranking.com/" color="primary" class="mt-4" style="text-decoration: none;min-width: 110px; font-size: 18px; ">OK</a> 
             
		</div>
	</div>

</div>