


<div class="kyc-h">
    <img src="assets/images/equentis_logo.png" alt="LOGO" class="logo">
</div> 
 
<h2 class="page_quot_risk">That Was Fast !!! Just Few More Steps And You Are Done.</h2>
<section>
    
    <form #riskForm="ngForm" (ngSubmit)="onSubmit(riskForm)">
    <div class="risk-widget">
        <p class="sub-h">
            <strong>  {{allDataSoored['userName']}},</strong> Please tell us a bit more about yourself.
        </p>

        <div class="risk-info" *ngIf="allDataSoored['qnaDataList'] && (onboard_category=='retail_new' || onboard_category=='retail_renewal')" [ngModelGroup]="'QnAList'">
            <div class="risk-info-row"  *ngFor='let item of allDataSoored.qnaDataList; let i = index;' >
            <div class="quest_title">{{i+1}}. {{item.question}}</div> 
             <mat-selection-list name="{{item.qid}}" [(ngModel)]="riskFormData.QnAList[item.qid]" [multiple]="false" (ngModelChange)="onNgModelChange($event)">
                <mat-list-option *ngFor='let options of item.options; let ind = index;' [value]="options.optionId" [class.warningOptions]="riskFormData.QnAList[item.qid] && riskFormData.QnAList[item.qid][0] == options.optionId && tooltipOptions[options.optionId]" [selected]="options.check">
                    {{options.answer}}   
                    <span class="tooltipOptions" *ngIf="riskFormData.QnAList[item.qid] && riskFormData.QnAList[item.qid][0] == options.optionId && tooltipOptions[options.optionId]">
                        <mat-icon svgIcon="shield-alert" style="height: 17px; width: 17px; position: relative; top: 4px;"></mat-icon> <b> Warning :</b> {{tooltipOptions[options.optionId]}}
                    </span> 
                    <p *ngIf="showScore" style="
                    font-size: 12px;
                    color: #fff;
                    position: absolute;
                    top: 50%;
                    right: -10px;
                    background: #a4a4a4;
                    min-width: 20px;
                    height: 20px;
                    border-radius: 50%; 
                    align-items: center;
                    justify-content: center;
                    line-height: 20px;
                    margin: 0;
                    margin-top: -10px;
                ">{{options.score}}</p>  
                </mat-list-option>
              </mat-selection-list>
 
            </div>
          </div>


          <div class="risk-info" *ngIf="allDataSoored['qnaDataList']  && (onboard_category=='dwn_new' ||  onboard_category=='dwn_renewal')" [ngModelGroup]="'QnAList'">
            <div class="risk-info-row"  *ngFor='let item of allDataSoored.qnaDataList; let i = index;' >
            <div class="quest_title">{{i+1}}. {{item.question}} 
            
                  
                      <mat-icon *ngIf="item.qid==11"
                      matTooltip="Shariah-compliant funds are governed by the requirements of Shariah law and the principles of the Muslim religion. Shariah-compliant funds are considered to be a type of socially responsible investing."
                      matTooltipPosition="above"
                      matTooltipClass="tooltip-popover" style=" cursor: pointer; height: 20px; width: 20px; position: relative; top: 4px; color: #f83f3f; ">info</mat-icon>
                   
                
            </div> 
             <mat-selection-list name="{{item.qid}}" [(ngModel)]="riskFormData.QnAList[item.qid]" [multiple]="false" (selectionChange)="onNgModelChange(item.qid,$event)">
                <mat-list-option *ngFor='let options of item.options; let ind = index;' [value]="options.optionId" [class.warningOptions]="(riskFormData.QnAList[item.qid] && riskFormData.QnAList[item.qid][0] == options.optionId && tooltipOptions[options.optionId]) || (LowestRating_SelectdQuestions[item.qid] === options.optionId)">
                    {{options.answer}} 
                    <span class="tooltipOptions" *ngIf="riskFormData.QnAList[item.qid] && riskFormData.QnAList[item.qid][0] == options.optionId && tooltipOptions[options.optionId]">
                        <mat-icon svgIcon="shield-alert" style="height: 17px; width: 17px; position: relative; top: 4px;"></mat-icon> <b> Warning :</b> {{tooltipOptions[options.optionId]}}
                    </span> 
                    <p *ngIf="showScore" style="
                    font-size: 12px;
                    color: #fff;
                    position: absolute;
                    top: 50%;
                    right: -10px;
                    background: #a4a4a4;
                    min-width: 20px;
                    height: 20px;
                    border-radius: 50%; 
                    align-items: center;
                    justify-content: center;
                    line-height: 20px;
                    margin: 0;
                    margin-top: -10px;
                "> {{options.score}}</p>  
                </mat-list-option>
              </mat-selection-list>
 
            </div>
             
           
        </div>
        
        <div class="mt-4 chk-info d-flex declareNotChecked" *ngIf="WarningOptions_Selected.length>0"> 
        <span>
            <mat-icon svgIcon="shield-alert" style="height: 17px; width: 17px; position: relative; top: 4px;color: red;"></mat-icon> <b style="color: red;"> Disclaimer :</b>
            {{WarningOptions_Disclaimer}}            
        </span>
       </div>

        <div class="mt-4 chk-info" *ngIf="WarningOptions_Selected.length==0" [class.declareNotChecked]="riskForm.form.controls.riskDeclareCheckbox?.errors"> 
             <mat-checkbox color="primary" name="riskDeclareCheckbox" [(ngModel)]="riskFormData['riskDeclareCheckbox']"></mat-checkbox>
             <span>I hereby declare that I have read, understood and personally accomplished this entire Risk Profiling Questionnaire and that the answers I have given are accurate. I understand the risk involved in investing in equities. I will exercise my own independent judgement in subscribing to the suitable package/s (if any) as per my Risk Profile Score.</span>
        </div>

        <div class="text-center mt-4">
            <button [disabled]="WarningOptions_Selected.length>0" style=" min-width: 150px; font-size: 18px; height: 44px; " mat-raised-button color="primary">Next <mat-icon matPrefix svgIcon="arrow-right"></mat-icon></button>
        </div>

        

    </div>
</form>
</section>
    


<ng-template #dialogRiskCompareRef let-data>
    <button mat-icon-button mat-dialog-close tabindex="-1" class="dialog-close-icon">
        <mat-icon svgIcon="close"></mat-icon> 
    </button>
     
     
    <mat-dialog-content>
 
  <div class="row row_riskCompare"> 
      <div class="col-6" *ngFor="let item of data.risk_profile | keyvalue:keyDescOrder">
         
        <h2 class="text-center" style=" font-size: 18px; font-weight: 700; margin-bottom: 4px; ">{{item.key | titlecase}} Risk Profile
            <small *ngIf="onboard_category=='dwn_renewal' && data.profileChanged.investment[item.key]"  style=" display: block; margin: 7px 0 5px 0; font-size: 13px; color: #6a6a6a; ">(Investible Surplus <strong>{{data.profileChanged.investment[item.key]}} Lac.</strong>)</small>
            <small *ngIf="onboard_category=='dwn_renewal' && !data.profileChanged.investment[item.key]"  style=" display: block; margin: 7px 0 5px 0; font-size: 13px; color: #6a6a6a; ">(Investible Surplus <strong>N/A</strong>)</small>
        </h2>  
         <p  class="text-center" [ngClass]="item.value"><strong>{{item.value | titlecase}} Risk</strong></p> 

        <p style=" font-weight: 600; margin-bottom: 8px;">Our {{item.value | titlecase}} Growth is characterized by -</p>
      
        <ol>
            <li *ngFor="let elm of data.risk_list[item.value]">{{elm}}</li>
         </ol>
 
      </div> 

  </div>

  <p *ngIf="onboard_category=='retail_renewal'" style="margin-top: 30px; font-size: 13px; line-height: 18px; font-weight: 600; color: #585858; "><strong style=" color: #000000; ">Note -</strong> 
    If you have chosen to change the risk profile to new risk profile, some of the recommended stocks may be replaced with new ones as per your updated risk profile. Please note that you will not receive any updates/alerts on your old stock recommendations.
 </p>

  <p *ngIf="onboard_category=='dwn_renewal'" style="margin-top: 30px; font-size: 13px; line-height: 18px; font-weight: 600; color: #585858; ">
    <strong style=" color: #000000; ">Note -</strong>
    If you have chosen to change the risk profile to new risk profile, some of the recommended stocks may be replaced with new ones as per your updated risk profile. Please note, you will not receive future  updates/alerts for the old replaced recommendations.
   </p>

<div class="pt-3 mt-4 mb-4 text-center">
    <button mat-raised-button color="primary" (click)="ContinueWith('old')" class="mr-3"
    style="background-color: #676767;font-size: 14px;padding-left: 25px;padding-right: 25px;">Continue with old risk profile </button>
    <button mat-raised-button color="primary"  (click)="ContinueWith('new')"
    style="font-size: 14px;padding-left: 25px;padding-right: 25px;">Go Ahead with new Risk Profile</button>
</div>

    </mat-dialog-content>
</ng-template>