<div class="login-page-body row no-gutters"> 
  <div class="login-banner col-sm-6 bg-color-gd" style="background-image: url('./assets/images/Left-Login-banner-full-BG.jpg');background-blend-mode: soft-light;
  background-color: #001e5a !important;"> 
    <img class="left-logo-img" src="./assets/images/equentis_logo.png" style="filter: brightness(0) invert(1);"/>
    <!-- <img class="left-banner-img" src="./assets/images/Left-Login-banner-img-1.png" /> -->
    <div class="lg-bn-info">
       <p>Few clicks away from starting your</p>
       <h4>Wealth Creation Journey</h4>
       <div class="qt">
         <i>“Risk is always there, You just have to move in the right direction.”</i>
       </div>
    </div>	
  </div> 

  <div class="login-form col-sm-6">
    <div class="form-group-body"> 
      <!-- <mat-icon *ngIf="otpSended" appLocationBack style="cursor: pointer;font-size: 30px;margin-right: 20px;">keyboard_backspace</mat-icon> -->
      <h2 class="loginHeading_2 mb-4" style=" display: flex; align-items: center; ">  
           R&R Customer Onboarding Process 
      </h2>  
      <!-- <h3 class="loginHeading_3 mb-3">{{GetStarted}}</h3> -->
      <h4 class="loginHeading_4 mb-5" [innerHTML]="otpMsg"></h4>  

       <form *ngIf="!otpSended" [formGroup]="loginForm" #loginFormRef="ngForm" class="mb-lg-5" (ngSubmit)="onSubmit()">  
        
          <div class="form-group mb-2">
            <label class="form-label-text">Email ID <em>*</em></label>
            <mat-form-field appearance="fill"> 
            <input type="text" matInput formControlName="email" readonly>
           </mat-form-field>
           </div>

           <div class="form-group mb-2">
            <label class="form-label-text">Mobile No. <em>*</em></label>
           <mat-form-field appearance="fill">  

            <input matInput required formControlName="phone" #elm_phone_no
                                    type="tel"
                                    gktTelInput  
                                    [gktTelWithHyphen]="true" 
                                    [gktTelInputOptions]="{initialCountry: 'in'}"
                                    (hasError)="hasErrorPhone('phone', $event)" 
                                    (blur)="loginForm.controls.phone.setValue(getEventVal($event));"
                                    > 
                                    <mat-error *ngIf="loginForm.controls.phone?.errors">
                                      Please enter valid phone number.
                                  </mat-error>
                                
            <!-- <input matInput formControlName="phone" #phone_no
                 gktTelInput
			        	[gktTelWithHyphen]="true"
                [gktTelInputOptions]="{initialCountry: 'in'}"
                (hasError)="hasErrorPhone('phone_no', $event)"  >  -->
           </mat-form-field>
          </div> 
     
           
        <div class="chk-info"  [class.declareNotChecked]="loginFormRef.submitted && loginForm.controls.declareCheckbox?.errors"> 
           <mat-checkbox color="primary" (change)='declareOnChange(elm_declareCheckbox,dialogDeclarationRef)' required formControlName="declareCheckbox" #elm_declareCheckbox >Please Provide Consent and Declaration.</mat-checkbox>
         </div>

         <!-- <p  class="text-left text-danger mt-4 mb-0">Login Error: {{loginErrorMSG}}</p>  -->
         <p  *ngIf="loginErrorMSG" class="text-left text-danger  mt-4 mb-0">Login Error: {{loginErrorMSG}}</p> 

         
       <div class="text-left pt-4">
        <button [disabled]="submitted"  mat-raised-button class="" color="accent">
          Send OTP  <mat-icon *ngIf="submitted"><mat-spinner color="warn" diameter="20"></mat-spinner></mat-icon>
        </button>
 
      </div> 
         
      </form>

    

      <form *ngIf="otpSended" [formGroup]="verifyOtpForm"  class="mb-lg-5" (ngSubmit)="onVerifyOtp()">  
         

        <div class="form-group">
          <label class="form-label-text">Enter OTP <em>*</em></label>
          <mat-form-field appearance="fill"> 
            <input type="text" matInput  formControlName="otp" (blur)="trimString('otp',$event)" >
         </mat-form-field>
        </div> 
    
     <div class="text-left pt-3">
      <button mat-raised-button class="" color="accent"> Verify OTP  </button>
     </div> 
       
    </form>

    <p class="copyright_text">Copyright © 2021 Equentis Wealth Advisory Services Ltd. All Rights Reserved.</p>

  </div>

   
  </div>

</div> 

 
 
 

<ng-template #dialogDeclarationRef let-data>

  <button mat-icon-button mat-dialog-close tabindex="-1" class="dialog-close-icon">
    <mat-icon svgIcon="close"></mat-icon> 
  </button>

  <app-client-consent-declaration></app-client-consent-declaration>

  <div class="text-center mt-4 mb-3">
    <button mat-stroked-button mat-dialog-close  class="mr-3" style=" min-width: 90px; ">Close</button> 
</div>

</ng-template>