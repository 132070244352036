import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs'; 
import {catchError, finalize } from 'rxjs/operators'; 
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router'; 
import Swal from 'sweetalert2';
import { DataFactoryService } from 'src/services/factory/data-factory.service'; 
import { environment } from 'src/environments/environment';
import { ServiceFactory } from '../factory/service-factory.service';
import { CommonService } from './common.service';
 

@Injectable({
  providedIn: 'root'
})
export class LoginService {

 
  serverUrl = environment.baseUrl;
  errorData: {};
  
  redirectUrl: string;
  currentUser:any;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService ,  
    public route: ActivatedRoute,
    public dataFactory: DataFactoryService,
    public serviceFactory: ServiceFactory,
    public commonService: CommonService,
    private router: Router,
    ) { }
    
 
   
    login(data) {
     debugger  
      return this.http.post<any>(`${this.serverUrl}sendOtp`, data).pipe( 
      finalize(() => {
         
      })
    );
  } 
  
  verifyOtp(data) {
     debugger  
      return this.http.post<any>(`${this.serverUrl}verifyOtp`, data).pipe( 
      finalize(() => {
         
      })
    );
  } 
 
  contactUs(email: string, message: string) {
    //debugger
    this.serviceFactory.loadingStart(".mat-dialog-container","Please wait while loading...","");
     return this.http.post<any>(`${this.serverUrl}contactUs`, {email: email, message: message}).pipe(
      
       finalize(() => {
        this.serviceFactory.loadingStop(".mat-dialog-container","");  
       })
     );
   } 


 


  isLoggedIn() {   
     //debugger    
     if (this.cookieService.get('trackerr_currentUser')) {
       this.currentUser = JSON.parse(this.cookieService.get('trackerr_currentUser'))
       return true;
     }     
     return false;
   }

   getAuthorizatioRole() {  
     //debugger 
      return this.currentUser.role;   
     }
  

  getCurrentUser() {  
    // debugger 
      return this.currentUser;   
     }
  

  getAuthorizationToken() { 
    return JSON.parse(this.cookieService.get('trackerr_user_token')); 
   }
 
   
   async logout(url) { 
     debugger
    
    
   
    this.cookieService.delete('trackerr_currentUser','/'); 
    this.cookieService.delete('trackerr_user_token','/');
      window.location = url;
   }

 
   logoutByClick(url) {
    debugger
    Swal.fire({ 
     title: 'Logout',
     html: 'Are you sure you want to Logout?', 
    // icon: 'warning', 
     customClass: {
         confirmButton: 'mat-flat-button mat-button-base mat-warn order-last',
         cancelButton: 'mat-stroked-button mat-button-base ',
         container: 'modal-yes-no Modal_Delete', 
         actions: 'modal-btn-yes-no mb-4',
        // closeButton: 'btn-modal-dismiss',
        header: 'pt-4', 
     },
     width: '36em',
     showCloseButton: true,
     buttonsStyling: false,
     showCancelButton: true,
     confirmButtonText: 'Logout',
     cancelButtonText: 'Cancel' ,       
 }).then((result) => {
   debugger 
   if(result.value){ 
      this.logout(url);   
    }
 }) 

  }
 
 
}
